import { push } from "connected-react-router";
import get from "lodash/get";

import { loginRefresh } from "actions";
import { LOGIN_REFRESH } from "constants/auth";
import PromiseWithGrouping from "utils/promiseWithGrouping";

const refreshTokenWithGrouping = new PromiseWithGrouping();

export const isAxiosRequest = action => action.payload && action.payload.request;

export default ({ dispatch, getState }) => next => async action => {
  if (
    // !isAxiosRequest(action) ||
    action.type === LOGIN_REFRESH.PENDING ||
    action.type === LOGIN_REFRESH.SUCCESS ||
    action.type === LOGIN_REFRESH.FAILURE
  ) {
    return next(action);
  }

  const isAxiosError = get(action, "error.isAxiosError");
  const isTokenInvalid = isAxiosError && action.error.response.status === 401;
  const is500Error = isAxiosError && action.error.response.status === 500;

  const state = getState();
  const {
    AccessToken,
    AccessTokenExpiration,
    AppUserGUID,
    RefreshToken,
  } = state.auth.login.authData;

  // Try to request token by refresh token if it is invalid
  if (AppUserGUID && RefreshToken && isTokenInvalid) {
    const { error } = await refreshTokenWithGrouping.run(LOGIN_REFRESH.SUCCESS, () =>
      dispatch(loginRefresh({ AppUserGUID, RefreshToken }))
    );

    if (error === undefined) {
      const retryResultAction = await dispatch(action.meta.previousAction);

      return next(retryResultAction);
    }

    if (Storage !== undefined) {
      localStorage.removeItem("customerAccessToken");
      localStorage.removeItem("customerAccessTokenExpiration");
      localStorage.removeItem("customerRefreshToken");
      localStorage.removeItem("customerRefreshTokenExpiration");
      localStorage.removeItem("customerAppUserGUID");
    }
    await dispatch(push("/login"));
  }

  // Update token by refresh token before it will become outdated (1 hours)
  if (
    AccessToken &&
    AccessTokenExpiration &&
    Date.now() > (AccessTokenExpiration - 3600 * 1) * 1000 &&
    AppUserGUID &&
    RefreshToken
  ) {
    await refreshTokenWithGrouping.run(LOGIN_REFRESH.SUCCESS, () =>
      dispatch(loginRefresh({ AppUserGUID, RefreshToken }))
    );
  }

  return next(action);
};
