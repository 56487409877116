import { combineReducers } from "redux";
import { modifyCampaigns } from "utils";
import { parseISO, format } from "date-fns";

import {
  CANCEL_CAMPAIGN,
  CLEAR_CANCEL_CAMPAIGN,
  CLEAR_TOGGLE_AUTO_REPEAT_CAMPAIGN,
  GET_ACTIVE_CAMPAIGNS_BY_LOCATION,
  GET_CAMPAIGNS_BY_LOCATION,
  GET_CAMPAIGN_REPORT,
  GET_AUTOREPEAT_REPORT,
  GET_FUTURE_CAMPAIGNS_BY_LOCATION,
  GET_INDIVIDUAL_CAMPAIGN_DATA,
  GET_PAST_CAMPAIGNS_BY_LOCATION,
  GET_PROBLEM_CAMPAIGNS_BY_LOCATION,
  TOGGLE_AUTO_REPEAT_CAMPAIGN,
} from "constants/campaigns";
import { SELECT_USER_LOCATION } from "constants/user";

const isLoadMoreButtonVisible = action => {
  const { Campaigns } = action.payload.data;
  const {
    query: { limit },
  } = action.meta.previousAction.meta;

  return Campaigns.length === limit;
};

const initialStateCampaignsRequest = {
  campaigns: [],
  error: undefined,
  isLoading: false,
  isLoadMoreButtonVisible: false,
};

const individualCampaignData = (state = { data: {}, error: false, isLoading: true }, action) => {
  switch (action.type) {
    case GET_INDIVIDUAL_CAMPAIGN_DATA.PENDING:
      return { ...state, isLoading: true };
    case GET_INDIVIDUAL_CAMPAIGN_DATA.SUCCESS:
      return { ...state, isLoading: false, data: action.payload.data, error: false };
    case GET_INDIVIDUAL_CAMPAIGN_DATA.FAILURE:
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
};

const campaignsRequest = (state = initialStateCampaignsRequest, action) => {
  switch (action.type) {
    case GET_CAMPAIGNS_BY_LOCATION.PENDING:
      return {
        ...state,
        campaigns: action.payload.request.params.offset === 0 ? [] : [...state.campaigns],
        isLoading: true,
      };
    case GET_CAMPAIGNS_BY_LOCATION.SUCCESS:
      return {
        ...state,
        campaigns: action.payload.data.Campaigns,
        error: undefined,
        isLoading: false,
      };
    case GET_CAMPAIGNS_BY_LOCATION.FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};

const pastCampaignsRequest = (state = initialStateCampaignsRequest, action) => {
  switch (action.type) {
    case GET_PAST_CAMPAIGNS_BY_LOCATION.PENDING:
      return {
        ...state,
        campaigns: action.payload.request.params.offset === 0 ? [] : [...state.campaigns],
        isLoading: true,
      };
    case GET_PAST_CAMPAIGNS_BY_LOCATION.SUCCESS:
      return {
        ...state,
        campaigns: [...state.campaigns, ...action.payload.data.Campaigns],
        error: undefined,
        isLoading: false,
        isLoadMoreButtonVisible: isLoadMoreButtonVisible(action),
      };
    case GET_PAST_CAMPAIGNS_BY_LOCATION.FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case TOGGLE_AUTO_REPEAT_CAMPAIGN.SUCCESS: {
      const { orderId, autoRepeatActive } = action.meta.previousAction.meta;
      return {
        ...state,
        campaigns: modifyCampaigns(orderId, state.campaigns, autoRepeatActive),
      };
    }
    case SELECT_USER_LOCATION:
      return {
        ...state,
        ...initialStateCampaignsRequest,
      };
    default:
      return state;
  }
};

const activeCampaignsRequest = (state = initialStateCampaignsRequest, action) => {
  switch (action.type) {
    case GET_ACTIVE_CAMPAIGNS_BY_LOCATION.PENDING:
      return {
        ...state,
        campaigns: action.payload.request.params.offset === 0 ? [] : [...state.campaigns],
        isLoading: true,
      };
    case GET_ACTIVE_CAMPAIGNS_BY_LOCATION.SUCCESS:
      return {
        ...state,
        campaigns: [...state.campaigns, ...action.payload.data.Campaigns],
        error: undefined,
        isLoading: false,
        isLoadMoreButtonVisible: isLoadMoreButtonVisible(action),
      };
    case GET_ACTIVE_CAMPAIGNS_BY_LOCATION.FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case TOGGLE_AUTO_REPEAT_CAMPAIGN.SUCCESS: {
      const { orderId, autoRepeatActive } = action.meta.previousAction.meta;
      return {
        ...state,
        campaigns: modifyCampaigns(orderId, state.campaigns, autoRepeatActive),
      };
    }
    case SELECT_USER_LOCATION:
      return {
        ...state,
        ...initialStateCampaignsRequest,
      };
    default:
      return state;
  }
};

const futureCampaignsRequest = (state = initialStateCampaignsRequest, action) => {
  switch (action.type) {
    case GET_FUTURE_CAMPAIGNS_BY_LOCATION.PENDING:
      return {
        ...state,
        campaigns: action.payload.request.params.offset === 0 ? [] : [...state.campaigns],
        isLoading: true,
      };
    case GET_FUTURE_CAMPAIGNS_BY_LOCATION.SUCCESS:
      return {
        ...state,
        campaigns: [...state.campaigns, ...action.payload.data.Campaigns],
        error: undefined,
        isLoading: false,
        isLoadMoreButtonVisible: isLoadMoreButtonVisible(action),
      };
    case GET_FUTURE_CAMPAIGNS_BY_LOCATION.FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case CANCEL_CAMPAIGN.SUCCESS: {
      const { campaignId } = action.meta.previousAction.meta;
      return {
        ...state,
        campaigns: state.campaigns.filter(campaign => campaign.Id !== campaignId),
      };
    }
    case TOGGLE_AUTO_REPEAT_CAMPAIGN.SUCCESS: {
      const { orderId, autoRepeatActive } = action.meta.previousAction.meta;
      return {
        ...state,
        campaigns: modifyCampaigns(orderId, state.campaigns, autoRepeatActive),
      };
    }
    case SELECT_USER_LOCATION:
      return {
        ...state,
        ...initialStateCampaignsRequest,
      };
    default:
      return state;
  }
};

const problemCampaignsRequest = (state = initialStateCampaignsRequest, action) => {
  switch (action.type) {
    case GET_PROBLEM_CAMPAIGNS_BY_LOCATION.PENDING:
      return {
        ...state,
        campaigns: action.payload.request.params.offset === 0 ? [] : [...state.campaigns],
        isLoading: true,
      };
    case GET_PROBLEM_CAMPAIGNS_BY_LOCATION.SUCCESS:
      return {
        ...state,
        campaigns: [...state.campaigns, ...action.payload.data.Campaigns],
        error: undefined,
        isLoading: false,
        isLoadMoreButtonVisible: isLoadMoreButtonVisible(action),
      };
    case GET_PROBLEM_CAMPAIGNS_BY_LOCATION.FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case TOGGLE_AUTO_REPEAT_CAMPAIGN.SUCCESS: {
      const { orderId, autoRepeatActive } = action.meta.previousAction.meta;
      return {
        ...state,
        campaigns: modifyCampaigns(orderId, state.campaigns, autoRepeatActive),
      };
    }
    case SELECT_USER_LOCATION:
      return {
        ...state,
        ...initialStateCampaignsRequest,
      };
    default:
      return state;
  }
};

const initialStateCancelCampaignRequest = {
  error: undefined,
  isLoading: false,
};

const cancelCampaignRequest = (state = initialStateCancelCampaignRequest, action) => {
  switch (action.type) {
    case CLEAR_CANCEL_CAMPAIGN:
      return {
        ...state,
        error: false,
        isLoading: false,
      };
    case CANCEL_CAMPAIGN.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case CANCEL_CAMPAIGN.SUCCESS:
      return {
        ...state,
        error: undefined,
        isLoading: false,
      };
    case CANCEL_CAMPAIGN.FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};

const initialStateToggleAutoRepeatCampaignRequest = {
  error: undefined,
  isLoading: false,
};

const toggleAutoRepeatCampaignRequest = (
  state = initialStateToggleAutoRepeatCampaignRequest,
  action
) => {
  switch (action.type) {
    case CLEAR_TOGGLE_AUTO_REPEAT_CAMPAIGN:
      return {
        ...state,
        error: false,
        isLoading: false,
      };
    case TOGGLE_AUTO_REPEAT_CAMPAIGN.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case TOGGLE_AUTO_REPEAT_CAMPAIGN.SUCCESS:
      return {
        ...state,
        error: undefined,
        isLoading: false,
      };
    case TOGGLE_AUTO_REPEAT_CAMPAIGN.FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};

// Normalizing data for successful and failed reponses for campaign reports. Returns a new state.
const updateReport = (state, campaignId, response, errorMessage) => {
  // Return a new state.
  return state.map(report => {
    // On a failed response return a new object with no reporting data.
    if (report.CampaignId === campaignId && response === null) {
      return {
        CampaignId: campaignId,
        errorMessage,
        isError: true,
        isLoading: false,
      };
    }
    // On a successful response update an existing report object with reporting data.
    // BarChart and PieChart Specific (Publisher Domain)
    // modifies API data to shape that horizontal bar chart and pie chart expects
    if (report.CampaignId === campaignId) {
      const barArrImpressionsPercent = [];
      const publisherDomainData = [];
      const barArrClicksPercent = [];
      const barArrClicks = [];
      const pieArr = [];
      const pieFillArr = [];
      let pubDomErr = false;
      if (response.PublisherDomainData && response.PublisherDomainData.length > 0) {
        const reversed = response.PublisherDomainData.reverse(); // need to re-order smallest to largest for the bar chart

        const total = reversed.length;

        reversed.forEach((item, i) => {
          let { domain } = item;

          if (domain === "facebook.com") {
            domain = "Facebook & Instagram";
          }

          // Convert long domain names to "example-long-doma...in.com"
          if (domain.length > 28) {
            domain = `${domain.slice(0, 23)}...${domain.slice(-5)}`;
          }

          const barObj = {
            Percent: item.ImpressionsPercent,
            Site: domain,
          };
          barArrImpressionsPercent.push(barObj);
          publisherDomainData.push(barObj);

          const barObjClicksPct = {
            Percent: Math.round(item.ClicksPercent),
            Site: domain,
          };
          barArrClicksPercent.push(barObjClicksPct);

          const barObjClicks = {
            Number: Math.round(item.clicks),
            Site: domain,
          };

          if (item.clicks > 0) {
            barArrClicks.push(barObjClicks);
          }

          const pieObj = {
            id: `${domain}`,
            label: `${domain}`,
            value: ((item.clicks / item.impressions) * 100).toFixed(2),
          };

          if (item.clicks > 0) {
            pieArr.push(pieObj);
          }
          // every other item add a fill pattern
          // patterns defined in PieChart component ResponsivePie props
          if (i % 2 === 0) {
            let fillDef = "dots";
            if (i > total / 2) {
              fillDef = "lines";
            }
            const pieFillObj = {
              match: {
                id: `${domain}`,
              },
              id: fillDef,
            };
            pieFillArr.push(pieFillObj);
          }
        });
      } else {
        // no publisher domain data
        pubDomErr = true;
      }
      //= [END] BarChart and PieChart (Publisher Domain)
      return {
        ...report,
        ...response,
        BarSitesImpressionsPct: barArrImpressionsPercent,
        BarSitesClicksPct: barArrClicksPercent,
        BarSitesClicks: barArrClicks,
        PieSites: pieArr,
        PieFill: pieFillArr,
        isLoading: false,
        PubDomainErr: pubDomErr,
        PublisherDomainData: publisherDomainData,
      };
    }
    return report;
  });
};

// Reports are stored in the array. When completed campaign modal is opened we add a new report to the array.
const campaignReports = (state = [], action) => {
  switch (action.type) {
    case GET_CAMPAIGN_REPORT.PENDING:
      // Check if there is a report with a campaign ID that matches a requested report's campaign ID.
      if (state.some(report => report.CampaignId === action.meta.campaignId)) {
        // Update a report object with a matching campaign ID or do nothing and return an unchanged report.
        return state.map(report => {
          if (report.CampaignId === action.meta.campaignId) {
            return { ...report, isLoading: true, hasMultipleDataPoints: false };
          }
          return report;
        });
      }
      // If there is no campaign report – add an object with campaign ID and loading status set to true.
      return [...state, { CampaignId: action.meta.campaignId, isLoading: true }];
    case GET_CAMPAIGN_REPORT.SUCCESS:
      // On successfull response we invoke a function to normalize the data and update the state.
      return updateReport(state, action.payload.data.CampaignId, action.payload.data);
    case GET_AUTOREPEAT_REPORT.SUCCESS: {
      const dataPointsMinimumLength = 3;
      // Check if there are enough data points (at least 3) to display an elegant chart.
      const { data } = action.payload.data.Data.chartData[0];
      const { campaignId } = action.meta.previousAction.meta;
      if (data.length >= dataPointsMinimumLength) {
        return state.map(report => {
          if (report.CampaignId === campaignId) {
            return { ...report, hasMultipleDataPoints: true };
          }
          return report;
        });
      }
      return state;
    }
    case GET_CAMPAIGN_REPORT.FAILURE:
      // On failed response we invoke a function to erase existing report data and update the state.
      return updateReport(
        state,
        action.meta.previousAction.meta.campaignId,
        null,
        action.error.response?.data?.message
      );
    default:
      return state;
  }
};

const updateChartReport = (chartData, totals) => {
  let chartDataError = false;
  let noChartData = false;
  let clicksData = [];
  let impressionsData = [];
  let revenueData = [];
  let orderData = [];
  let dataSeriesDates = { startDate: "", endDate: "" };
  if (chartData.length > 0) {
    chartData.forEach(item => {
      switch (item.id) {
        case "Clicks":
          if (item?.data.length > 1) {
            clicksData = item;
          } else {
            noChartData = true;
            chartDataError = true;
          }
          break;
        case "Impressions":
          if (item?.data.length > 1) {
            impressionsData = item;
            // Get the data series start and end dates. Format them to "M/dd/yyyy"
            const startDate = parseISO(impressionsData.data.at(0).x);
            const formatedStartDate = format(startDate, "M/dd/yyyy");
            const endDate = parseISO(impressionsData.data.at(-1).x);
            const formatedEndDate = format(endDate, "M/dd/yyyy");
            dataSeriesDates = { startDate: formatedStartDate, endDate: formatedEndDate };
          } else {
            noChartData = true;
            chartDataError = true;
          }
          break;
        case "Revenue":
          revenueData = item;
          break;
        case "Total Orders":
          orderData = item;
          break;
        default:
          console.log("Something is wrong...but no data error");
      }
    });
    if (!noChartData) {
      // setDataDaily(data);
      chartDataError = false;
    } else {
      chartDataError = true;
    }
  } else {
    // no data set chartDataError true
    chartDataError = true;
  }
  return {
    Totals: totals,
    ChartData: chartData,
    ChartClicks: clicksData,
    ChartImpressions: impressionsData,
    ChartRevenue: revenueData,
    ChartOrders: orderData,
    ChartDataError: chartDataError,
    errorMessage: undefined,
    isError: false,
    isLoading: false,
    dataSeriesDates,
  };
};

const campaignAutorepeatReports = (state = {}, action) => {
  switch (action.type) {
    case GET_AUTOREPEAT_REPORT.PENDING:
      return {
        ...state,
        isLoading: true,
      };

    case GET_AUTOREPEAT_REPORT.SUCCESS:
      return updateChartReport(action.payload.data.Data.chartData, action.payload.data.Data.totals);

    case GET_AUTOREPEAT_REPORT.FAILURE:
      return {
        ...state,
        errorMessage: action.error.response?.data?.message,
        isError: true,
        isLoading: false,
      };

    default:
      return state;
  }
};

const campaignsReducer = combineReducers({
  activeCampaignsRequest,
  campaignReports,
  campaignAutorepeatReports,
  campaignsRequest,
  cancelCampaignRequest,
  futureCampaignsRequest,
  individualCampaignData,
  pastCampaignsRequest,
  problemCampaignsRequest,
  toggleAutoRepeatCampaignRequest,
});

export default campaignsReducer;
