import {
  ADD_PAYMENT,
  CLEAR_USER_CARD_DATA,
  CLEAR_USER_DATA,
  CONTACT_CUSTOMER_SERVICE,
  CREATE_CARD_TOKEN,
  CREATE_STRIPE_ERROR,
  DELETE_PAYMENT,
  EDIT_PAYMENT,
  GET_USER,
  RESET_CONTACT_FORM,
  SELECT_PAYMENT_DEFAULT,
  SELECT_USER_LOCATION,
  TOGGLE_ADD_NEW_CARD,
  TOGGLE_CARD_FORM,
  TOGGLE_MAINTENANCE_NOTIFICATION,
  USER_INITIALIZED,
} from "constants/user";

/**
 * Get user details for self
 */
export const loadUser = () => ({
  types: [GET_USER.PENDING, GET_USER.SUCCESS, GET_USER.FAILURE],
  payload: {
    request: {
      url: "user",
    },
  },
});

/**
 * Clear user data
 */
export const clearUserData = () => ({
  type: CLEAR_USER_DATA,
});

export const clearUserCardData = () => ({
  type: CLEAR_USER_CARD_DATA,
});

export const createStripeTokenError = () => ({
  type: CREATE_STRIPE_ERROR,
});

/**
 * Contact Local Hero customer service
 */
export const contactCustomerService = data => ({
  types: [
    CONTACT_CUSTOMER_SERVICE.PENDING,
    CONTACT_CUSTOMER_SERVICE.SUCCESS,
    CONTACT_CUSTOMER_SERVICE.FAILURE,
  ],
  payload: {
    request: {
      method: "post",
      url: "user/contact",
      data,
    },
  },
});

/**
 * Select locationId
 * @param {string} locationSelected
 */
export const selectUserLocation = locationSelected => ({
  type: SELECT_USER_LOCATION,
  locationSelected,
});

export const createCardToken = (cardElement, name, stripe) => ({
  type: CREATE_CARD_TOKEN,
  cardElement,
  name,
  stripe,
});

export const setIsAddNewCardOpen = () => ({
  type: TOGGLE_ADD_NEW_CARD,
});

/**
 * Add a payment source for this user
 * @param {object} StripeSourceToken
 */
export const addUserPayment = ({
  card: {
    brand: Brand,
    exp_month: ExpMonth,
    exp_year: ExpYear,
    last4: Last4,
    name: Name,
    address_zip: AddressZip,
  },
  id,
}) => ({
  types: [ADD_PAYMENT.PENDING, ADD_PAYMENT.SUCCESS, ADD_PAYMENT.FAILURE],
  payload: {
    request: {
      method: "post",
      url: `payment-sources`,
      data: {
        StripeSourceToken: id,
      },
    },
  },
  meta: {
    Brand,
    ExpMonth,
    ExpYear,
    IsDefault: false,
    Last4,
    Name,
    AddressZip,
  },
});

export const editUserPayment = (cardData, id) => {
  const { zip, expMonth, expYear, name } = cardData;
  return {
    types: [EDIT_PAYMENT.PENDING, EDIT_PAYMENT.SUCCESS, EDIT_PAYMENT.FAILURE],
    payload: {
      request: {
        method: "put",
        url: `payment-sources/${id}`,
        data: {
          StripeSourceToken: id,
          address_zip: zip,
          exp_month: expMonth,
          exp_year: expYear,
          name,
        },
      },
    },
    meta: {
      id,
      zip,
      expMonth,
      expYear,
      name,
    },
  };
};

/**
 * Mark payment source default
 * @param {string} Id paymentSourceId
 */
export const selectDefaultUserPayment = Id => ({
  types: [
    SELECT_PAYMENT_DEFAULT.PENDING,
    SELECT_PAYMENT_DEFAULT.SUCCESS,
    SELECT_PAYMENT_DEFAULT.FAILURE,
  ],
  payload: {
    request: {
      method: "put",
      url: `payment-sources/${Id}/default`,
    },
  },
  meta: {
    Id,
  },
});

/**
 * Delete an existing payment source
 * @param {String} Id paymentSourceId
 */
export const deleteUserPayment = Id => ({
  types: [DELETE_PAYMENT.PENDING, DELETE_PAYMENT.SUCCESS, DELETE_PAYMENT.FAILURE],
  payload: {
    request: {
      method: "delete",
      url: `payment-sources/${Id}`,
    },
  },
  meta: {
    Id,
  },
});

export const initializationComplete = () => ({
  type: USER_INITIALIZED,
  payload: {
    isInitialized: true,
  },
});

export const setIsEditCardFormOpen = id => ({
  type: TOGGLE_CARD_FORM,
  id,
});

export const toggleMaintenanceNotification = () => ({
  type: TOGGLE_MAINTENANCE_NOTIFICATION,
});

export const resetContactForm = () => ({
  type: RESET_CONTACT_FORM,
});
