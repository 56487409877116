import { useState, useEffect } from "react";

const useWindowWidth = (widthBreakpoint = 450) => {
  const [isLong, setLong] = useState(true);

  useEffect(() => {
    if (window.innerWidth < widthBreakpoint) {
      setLong(false);
    } else {
      setLong(true);
    }

    const onResize = () => {
      if (window.innerWidth < widthBreakpoint) {
        setLong(false);
      } else {
        setLong(true);
      }
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, [widthBreakpoint]);

  return isLong;
};

export default useWindowWidth;
