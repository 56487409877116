export const usaRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
export const canadaRegex = /^\s*[a-ceghj-npr-tvxy]\d[a-ceghj-npr-tv-z](\s)?\d[a-ceghj-npr-tv-z]\d\s*$/i;

export const months = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
];

// Generates an array of year options (51) for the year selector => [{value: 2021, label: "2021"}, {...}]
export const getYears = () => {
  const thisYear = new Date().getFullYear();
  const years = [];
  // Loops 51 times (that's maximum card validity accepted by stripe).
  for (let index = 0; index < 51; index++) {
    if (!index) {
      years.push({ value: thisYear, label: thisYear });
    } else {
      const previousYear = years[index - 1];
      years.push({ ...previousYear, value: previousYear.value + 1, label: previousYear.label + 1 });
    }
  }
  return years;
};
