import { initializationComplete, loadLocations, loadUser, getBrandCopy } from "actions";
import { LOGIN, LOGIN_REFRESH, SET_AUTH_FROM_LOCAL } from "constants/auth";

export default ({ dispatch, getState }) => next => async action => {
  if ([LOGIN.SUCCESS, LOGIN_REFRESH.SUCCESS, SET_AUTH_FROM_LOCAL].includes(action.type)) {
    await next(action);
    const { error } = await dispatch(loadUser());
    if (error === undefined) {
      await dispatch(loadLocations());
      // Get recorded brandId after the user data has been fetched.
      const { BrandId } = getState().user.userDataRequest.userData;
      await dispatch(getBrandCopy(BrandId));
      dispatch(initializationComplete());
    }
  }

  return next(action);
};
