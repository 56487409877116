import { CREATE_CARD_TOKEN } from "constants/user";
import { addUserPayment, createStripeTokenError } from "actions";

export default ({ dispatch }) => next => action => {
  const getTokenFromStripe = async ({ cardElement, name, stripe }) => {
    try {
      // Try generating stripe token with data provided from stripe element.
      const { token } = await stripe.createToken(cardElement, name);
      // Dispatch an action to create a record with user payment in the database using the generated token.
      dispatch(addUserPayment(token));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log("Error generating the stripe token:", error);
      // We don't want to show this error message ^^ to the user because it's pretty long and confusing.
      dispatch(createStripeTokenError());
    }
  };
  if (action.type === CREATE_CARD_TOKEN) {
    getTokenFromStripe(action);
  }

  return next(action);
};
