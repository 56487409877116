import { HEALTH_CHECK } from "constants/health";

/**
 * Health check
 */
export const healthCheck = () => ({
  types: [HEALTH_CHECK.PENDING, HEALTH_CHECK.SUCCESS, HEALTH_CHECK.FAILURE],
  payload: {
    request: {
      url: "health",
    },
  },
});
