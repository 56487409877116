import { asyncActionType } from "utils/constants";

export const PLACE_ORDER = asyncActionType("PLACE_ORDER");
export const GET_ORDERS_BY_LOCATION = asyncActionType("GET_ORDERS_BY_LOCATION");
export const CLEAR_PLACE_ORDER = "CLEAR_PLACE_ORDER";

export const PROMO_CODE = asyncActionType("PROMO_CODE");
export const GIFT_CARD_ADD = "GIFT_CARD_ADD";
export const GIFT_CARD_REMOVE = "GIFT_CARD_REMOVE";
export const CLEAR_PROMO_CODE = "CLEAR_PROMO_CODE";
export const UPDATE_PROMO_CODE = "UPDATE_PROMO_CODE";
