import { combineReducers } from "redux";
import { mapKeys, camelCase } from "lodash";
import { parse } from "date-fns";

import {
  CAMPAIGN_PROMOTION_FIRST_MOUNT,
  CLEAR_SELECTIONS,
  GET_OFFERS_BY_LOCATION,
  GET_STARTED,
  SELECT_PROMOTION,
  SELECT_OFFER_SIZE,
  SELECT_OFFER,
  SELECT_PAYMENT_DISCLAIM,
  SELECT_SCHEDULE_CONFIRM,
  SELECT_SCHEDULE_DATE,
  SELECT_SCHEDULE_DISCLAIM,
  TOGGLE_AUTO_REPEAT,
  SELECT_SCHEDULE_REPEAT,
  SELECT_SCHEDULE_START,
  SELECT_SUBCATEGORY,
  SELECT_SUMMARY,
  SELECT_CAMPAIGN_LENGTH,
  PREPOPULATE_SELECTIONS,
  SAVE_SCHEDULE_DATE_PICKER,
  GET_HOLIDAYS,
} from "constants/offers";

import {
  CLEAR_PROMO_CODE,
  GIFT_CARD_ADD,
  GIFT_CARD_REMOVE,
  PROMO_CODE,
  UPDATE_PROMO_CODE,
} from "constants/orders";

const initialStateOffersRequest = {
  isLoading: false,
  promotions: { activePromotions: [], inactivePromotions: [] },
  offers: [],
};

// Makes a shallow conversion to camelCase.
const toCamelCase = object => {
  return mapKeys(object, (value, key) => camelCase(key));
};

// Sorting promotions from the response: A > Z.
const sortAlphabetically = promotions =>
  promotions.sort((a, b) => {
    return a.Name.toUpperCase() > b.Name.toUpperCase() ? 1 : -1;
  });

const offersRequest = (state = initialStateOffersRequest, action) => {
  switch (action.type) {
    case GET_OFFERS_BY_LOCATION.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_OFFERS_BY_LOCATION.SUCCESS: {
      const allPromotions = action.payload.data.Promotions;
      let activePromotions;
      let inactivePromotions;
      // If promotion array contains values, then filter and sort it alphabetically.
      if (allPromotions.length) {
        activePromotions = sortAlphabetically(
          allPromotions.filter(promotion => {
            return promotion.Active && promotion.IsVisible && promotion.HasActiveOffers;
          })
        );
        inactivePromotions = sortAlphabetically(
          allPromotions.filter(promotion => promotion.IsVisible && !promotion.HasActiveOffers)
        );
      } else {
        // If promotions array is empty, then set both promotion types to empty arrays.
        activePromotions = [];
        inactivePromotions = [];
      }
      return {
        ...state,
        isLoading: false,
        promotions: { ...state.promotions, activePromotions, inactivePromotions },
        offers: action.payload.data.Offers,
      };
    }
    case GET_OFFERS_BY_LOCATION.FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};

const initialStateOfferSelect = {
  getStarted: false,
  campaignPromotionFirstMount: false,
  campaignLength: 7,
  promotion: false,
  subcategory: false,
  offer: false,
  offerName: false,
  offerSubcategory: false,
  offerFirstAvailableLaunchDate: false,
  offerLastAvailableLaunchDate: false,
  size: false,
  scheduleStart: true,
  scheduleAutoRepeat: true,
  scheduleRepeat: 0,
  scheduleDisclaim: false,
  scheduleConfirm: false,
  summary: false,
  paymentDisclaim: false,
  promoCode: { code: "", isLoading: false, error: "" },
  giftCards: [],
  scheduleDatePicker: false,
  weekends: null,
};

const offerSelect = (state = initialStateOfferSelect, action) => {
  switch (action.type) {
    case GET_STARTED:
      return {
        ...state,
        getStarted: action.getStarted,
      };
    case CLEAR_SELECTIONS:
      return {
        ...state,
        campaignPromotionFirstMount: false,
        getStarted: false,
        giftCards: [],
        offer: false,
        offerFirstAvailableLaunchDate: false,
        offerLastAvailableLaunchDate: false,
        offerName: false,
        offerSubcategory: false,
        paymentDisclaim: false,
        promoCode: { code: "", isLoading: false, error: "" },
        promotion: false,
        scheduleAutoRepeat: true,
        scheduleConfirm: false,
        scheduleDisclaim: false,
        scheduleRepeat: 0,
        scheduleStart: true,
        size: false,
        subcategory: false,
        summary: false,
      };
    case PREPOPULATE_SELECTIONS:
      return {
        ...state,
        campaignPromotionFirstMount: false,
        getStarted: false,
        giftCards: [],
        offer: action.offerId,
        offerFirstAvailableLaunchDate: action.firstAvailableLaunchDate,
        offerLastAvailableLaunchDate: action.lastAvailableLaunchDate,
        offerName: action.offerName,
        offerSubcategory: action.subcategoryId,
        paymentDisclaim: false,
        promoCode: { code: "", isLoading: false, error: "" },
        promotion: action.promotionId,
        scheduleAutoRepeat: false, // Hardcoded to false for now.
        scheduleConfirm: false,
        scheduleDisclaim: true, // Hardcoded to true for now.
        scheduleRepeat: 0,
        scheduleStart: true,
        size: action.campaignSize,
        subcategory: false,
        summary: false,
        // new
        offerAvailableDays: action.availableDays, // not included in clear
      };
    case CAMPAIGN_PROMOTION_FIRST_MOUNT:
      return {
        ...state,
        campaignPromotionFirstMount: true,
      };
    case SELECT_PROMOTION:
      return {
        ...state,
        promotion: action.promotion,
        subcategory: false,
        offer: false,
        offerName: false,
        offerSubcategory: false,
        offerFirstAvailableLaunchDate: false,
        offerLastAvailableLaunchDate: false,
        size: false,
        scheduleStart: true,
        scheduleDate: false,
        scheduleDatePicker: false,
        scheduleRepeat: 0,
        scheduleDisclaim: false,
        scheduleConfirm: false,
        summary: false,
        paymentDisclaim: false,
      };
    case SELECT_SUBCATEGORY:
      return {
        ...state,
        subcategory: action.subcategory,
        offer: false,
        offerName: false,
        offerSubcategory: false,
        offerFirstAvailableLaunchDate: false,
        offerLastAvailableLaunchDate: false,
        size: false,
        scheduleStart: true,
        scheduleDate: false,
        scheduleDatePicker: false,
        scheduleRepeat: 0,
        scheduleDisclaim: false,
        scheduleConfirm: false,
        summary: false,
        paymentDisclaim: false,
      };
    case SELECT_OFFER:
      return {
        ...state,
        offer: action.Id,
        offerAvailableDays: action.AvailableDays,
        offerName: action.Name,
        offerSubcategory: action.SubcategoryId,
        offerFirstAvailableLaunchDate: action.FirstAvailableLaunchDate,
        offerLastAvailableLaunchDate: action.LastAvailableLaunchDate,
        size: false,
        scheduleStart: true,
        scheduleDate: false,
        scheduleDatePicker: false,
        scheduleRepeat: 1,
        scheduleDisclaim: false,
        scheduleConfirm: false,
        summary: false,
        promoCode: { code: "", isLoading: false, error: "" },
        giftCards: [],
        paymentDisclaim: false,
        minimumStartDate: action.MinimumStartDate,
      };
    case SELECT_OFFER_SIZE:
      return {
        ...state,
        size: action.size,
        scheduleStart: true,
        scheduleDisclaim: false,
        scheduleConfirm: false,
        summary: false,
        paymentDisclaim: false,
      };
    case SELECT_SCHEDULE_START:
      return {
        ...state,
        scheduleStart: action.scheduleStart,
        scheduleConfirm: false,
        summary: false,
        paymentDisclaim: false,
      };
    case TOGGLE_AUTO_REPEAT:
      return {
        ...state,
        scheduleAutoRepeat: !state.scheduleAutoRepeat,
      };
    case SELECT_SCHEDULE_DATE:
      return {
        ...state,
        scheduleDate: action.scheduleDate,
        summary: false,
        paymentDisclaim: false,
      };
    case SAVE_SCHEDULE_DATE_PICKER:
      return {
        ...state,
        scheduleDatePicker: action.scheduleDate,
        summary: false,
        paymentDisclaim: false,
      };
    case SELECT_CAMPAIGN_LENGTH:
      return {
        ...state,
        campaignLength: action.campaignLength,
        summary: false,
        paymentDisclaim: false,
      };
    case SELECT_SCHEDULE_REPEAT:
      return {
        ...state,
        scheduleRepeat: action.scheduleRepeat,
        summary: false,
        paymentDisclaim: false,
      };
    case SELECT_SCHEDULE_DISCLAIM:
      return {
        ...state,
        scheduleDisclaim: action.scheduleDisclaim,
        scheduleConfirm: false,
        summary: false,
        paymentDisclaim: false,
      };
    case SELECT_SCHEDULE_CONFIRM:
      return {
        ...state,
        scheduleConfirm: action.scheduleConfirm,
        summary: false,
        paymentDisclaim: false,
      };
    case SELECT_SUMMARY:
      return {
        ...state,
        summary: action.summary,
        paymentDisclaim: false,
      };
    case SELECT_PAYMENT_DISCLAIM:
      return {
        ...state,
        paymentDisclaim: action.paymentDisclaim,
      };
    case GIFT_CARD_ADD:
      return {
        ...state,
        giftCards: [...state.giftCards, action.giftCard],
      };
    case GIFT_CARD_REMOVE:
      return {
        ...state,
        giftCards: state.giftCards.filter(giftCard => giftCard.id !== action.payload.id),
      };
    case PROMO_CODE.PENDING:
      if (action.meta.codeType === "DISCOUNT") {
        return {
          ...state,
          promoCode: { ...state.promoCode, error: action.error, isLoading: true },
        };
      }
      return {
        ...state,
        giftCards: state.giftCards.map(giftCard =>
          giftCard.id === action.meta.id
            ? { ...giftCard, error: action.error, isLoading: true }
            : giftCard
        ),
      };
    case PROMO_CODE.SUCCESS: {
      const { meta } = action.meta.previousAction;
      const data = toCamelCase(action.payload.data);
      if (meta.codeType === "DISCOUNT") {
        return {
          ...state,
          promoCode: {
            ...data,
            code: meta.code,
            isLoading: false,
          },
        };
      }
      if (state.giftCards.some(giftCard => giftCard.code === meta.code)) {
        return {
          ...state,
          giftCards: state.giftCards.map(giftCard =>
            giftCard.id === meta.id
              ? {
                  ...giftCard,
                  error: "Duplicate Gift Card",
                  isLoading: false,
                }
              : giftCard
          ),
        };
      }
      return {
        ...state,
        giftCards: state.giftCards.map(giftCard => {
          return giftCard.id === meta.id
            ? {
                ...giftCard,
                ...data,
                code: meta.code,
                isLoading: false,
              }
            : giftCard;
        }),
      };
    }
    case PROMO_CODE.FAILURE: {
      const { data, status } = action.error.response;
      const { meta } = action.meta.previousAction;
      if (meta.codeType === "DISCOUNT") {
        return {
          ...state,
          promoCode: {
            ...state.promoCode,
            error: status === 400 ? data.message : "Promo code not valid",
            isLoading: false,
          },
        };
      }
      return {
        ...state,
        giftCards: state.giftCards.map(giftCard =>
          giftCard.id === meta.id
            ? {
                code: meta.code,
                error: status === 400 ? data.message : "Gift card not valid",
                isLoading: false,
              }
            : giftCard
        ),
      };
    }

    case UPDATE_PROMO_CODE:
      return { ...state, promoCode: { ...state.promoCode, code: action.value } };
    case CLEAR_PROMO_CODE:
      if (action.payload.codeType === "DISCOUNT") {
        return {
          ...state,
          promoCode: { code: "", error: "", isLoading: false },
        };
      }
      return {
        ...state,
        giftCards: state.giftCards.map(giftCard =>
          giftCard.id === action.payload.id
            ? { ...giftCard, error: false, isLoading: false }
            : giftCard
        ),
      };
    case GET_HOLIDAYS.SUCCESS: {
      const dates = action.payload.data.WeekendsAndHolidays.map(
        date => new Date(parse(date, "yyyy-MM-dd HH:mm:ss", new Date()))
      );
      return { ...state, weekends: dates };
    }
    case GET_HOLIDAYS.PENDING:
      return state;
    case GET_HOLIDAYS.FAILURE:
      return state;
    default:
      return state;
  }
};

const offersReducer = combineReducers({
  offersRequest,
  offerSelect,
});

export default offersReducer;
