import {
  CAMPAIGN_PROMOTION_FIRST_MOUNT,
  CLEAR_SELECTIONS,
  GET_OFFERS_BY_BRAND,
  GET_OFFERS_BY_LOCATION,
  GET_STARTED,
  SELECT_PROMOTION,
  SELECT_OFFER_SIZE,
  SELECT_OFFER,
  SELECT_PAYMENT_DISCLAIM,
  SELECT_SCHEDULE_CONFIRM,
  SELECT_SCHEDULE_DATE,
  SELECT_SCHEDULE_DISCLAIM,
  TOGGLE_AUTO_REPEAT,
  SELECT_SCHEDULE_REPEAT,
  SELECT_SCHEDULE_START,
  SELECT_SUBCATEGORY,
  SELECT_SUMMARY,
  PREPOPULATE_SELECTIONS,
  SAVE_SCHEDULE_DATE_PICKER,
  SELECT_CAMPAIGN_LENGTH,
  GET_HOLIDAYS,
} from "constants/offers";

/**
 * Get all active offers by brand
 * @param {number} brandId
 */
export const loadOffersByBrand = brandId => ({
  types: [GET_OFFERS_BY_BRAND.PENDING, GET_OFFERS_BY_BRAND.SUCCESS, GET_OFFERS_BY_BRAND.FAILURE],
  payload: {
    request: {
      url: `brands/${brandId}/offers`,
    },
  },
});

/**
 * Get all active offers by location
 * @param {number} locationId
 */
export const loadOffersByLocation = locationId => ({
  types: [
    GET_OFFERS_BY_LOCATION.PENDING,
    GET_OFFERS_BY_LOCATION.SUCCESS,
    GET_OFFERS_BY_LOCATION.FAILURE,
  ],
  payload: {
    request: {
      url: `locations/${locationId}/offers`,
    },
  },
});

export const getHolidays = locationId => {
  return {
    types: [GET_HOLIDAYS.PENDING, GET_HOLIDAYS.SUCCESS, GET_HOLIDAYS.FAILURE],
    payload: {
      request: {
        url: `/scheduling`,
      },
    },
  };
};

export const selectGetStarted = () => {
  return {
    type: GET_STARTED,
    getStarted: true,
  };
};

export const clearSelections = () => {
  return {
    type: CLEAR_SELECTIONS,
  };
};

export const prepopulateSelections = ({
  availableDays,
  firstAvailableLaunchDate,
  lastAvailableLaunchDate,
  offerId,
  offerName,
  promotionId,
  subcategoryId,
  campaignSize,
}) => {
  return {
    type: PREPOPULATE_SELECTIONS,
    availableDays,
    firstAvailableLaunchDate,
    lastAvailableLaunchDate,
    offerId,
    offerName,
    promotionId,
    subcategoryId,
    campaignSize,
  };
};

export const campaignPromotionFirstMount = () => {
  return {
    type: CAMPAIGN_PROMOTION_FIRST_MOUNT,
  };
};

export const selectPromotion = promotion => {
  return {
    type: SELECT_PROMOTION,
    promotion,
  };
};

export const selectSubcategory = subcategory => ({
  type: SELECT_SUBCATEGORY,
  subcategory,
});

export const selectOffer = ({
  AvailableDays,
  Name,
  Id,
  SubcategoryId,
  FirstAvailableLaunchDate,
  LastAvailableLaunchDate,
  MinimumStartDate,
}) => {
  return {
    type: SELECT_OFFER,
    AvailableDays,
    Name,
    Id,
    SubcategoryId,
    FirstAvailableLaunchDate,
    LastAvailableLaunchDate,
    MinimumStartDate,
  };
};

export function selectOfferFromAlert(offerPromotion, offerSubcategory, offerID, offerName) {
  return function(dispatch) {
    dispatch(clearSelections());
    dispatch(selectPromotion(offerPromotion));
    dispatch(selectSubcategory(offerSubcategory));
    dispatch(selectOffer(offerID, offerName, offerSubcategory));
  };
}

export const selectCampaignSize = size => ({
  type: SELECT_OFFER_SIZE,
  size,
});

export const selectScheduleStart = scheduleStart => ({
  type: SELECT_SCHEDULE_START,
  scheduleStart,
});

export const toggleAutoRepeat = () => ({
  type: TOGGLE_AUTO_REPEAT,
});

export const selectScheduleDate = scheduleDate => ({
  type: SELECT_SCHEDULE_DATE,
  scheduleDate,
});

export const saveScheduleDatePicker = scheduleDate => ({
  type: SAVE_SCHEDULE_DATE_PICKER,
  scheduleDate,
});

export const selectScheduleRepeat = scheduleRepeat => ({
  type: SELECT_SCHEDULE_REPEAT,
  scheduleRepeat,
});

export const selectScheduleDisclaim = scheduleDisclaim => ({
  type: SELECT_SCHEDULE_DISCLAIM,
  scheduleDisclaim,
});

export const selectScheduleConfirm = () => ({
  type: SELECT_SCHEDULE_CONFIRM,
  scheduleConfirm: true,
});

export const selectSummary = summary => ({
  type: SELECT_SUMMARY,
  summary,
});

export const selectPaymentDisclaim = paymentDisclaim => ({
  type: SELECT_PAYMENT_DISCLAIM,
  paymentDisclaim,
});

export const selectCampaignLength = campaignLength => ({
  type: SELECT_CAMPAIGN_LENGTH,
  campaignLength,
});
