import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import { loadNotViewedAlertsTotal } from "actions";
import { ICONS } from "constants/assets";
import { useWindowWidth } from "hooks";

const navWidthBreakpoint = 450;
const navStart = {
  long: "Start Driving Sales",
  short: "Start",
};
const navStatus = {
  long: "Campaign Status",
  short: "Status",
};
const navAlerts = {
  long: "Alerts",
  short: "Alerts",
};

const LocationNav = () => {
  const isLong = useWindowWidth(navWidthBreakpoint);
  const alertsNumber = useSelector(state => state.alerts.alertsTotalRequest.response);
  const locationSelected = useSelector(
    state => state.user.userDataRequest.userData.locationSelected
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (locationSelected !== undefined) {
      dispatch(loadNotViewedAlertsTotal(locationSelected));
    }
  }, [dispatch, locationSelected]);

  return (
    <section className="section-location-nav">
      <div className="container-location-nav">
        <div className="location-nav-inner">
          <ul className="location-nav-list">
            <li>
              <NavLink activeClassName="active" to="/start">
                {isLong ? navStart.long : navStart.short}
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/status">
                {isLong ? navStatus.long : navStatus.short}
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" className="alerts" to="/alerts">
                <p>{isLong ? navAlerts.long : navAlerts.short}</p>
                <div className="alertsIconAndNumber">
                  <img alt="alerts" className="alertsIcon" src={ICONS.ALERT} />
                  {alertsNumber > 0 && (
                    <div className="alertsNumber">
                      <p>{alertsNumber}</p>
                    </div>
                  )}
                </div>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default memo(LocationNav);
