import { SET_DEEP_LINK_PATH, SET_DEEP_LINK_REDIRECTED } from "constants/path";

/**
 * Set deep link path
 * @param {object} pathname
 */
export const setDeepLinkPath = deepLinkData => ({
  type: SET_DEEP_LINK_PATH,
  deepLinkData,
});

/**
 * Set deep link redirected
 */
export const setDeepLinkRedirected = () => ({
  type: SET_DEEP_LINK_REDIRECTED,
});
