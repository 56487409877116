import { combineReducers } from "redux";

import { SET_DEEP_LINK_PATH, SET_DEEP_LINK_REDIRECTED } from "constants/path";

const initialStatePath = {
  deepLinkPath: "",
  deepLinkRedirected: false,
  access: {
    reporting: false,
  },
};

const deepLinking = (state = initialStatePath, action) => {
  switch (action.type) {
    case SET_DEEP_LINK_PATH:
      return {
        ...state,
        deepLinkPath: action.deepLinkData.pathname,
        access: action.deepLinkData.access,
      };
    case SET_DEEP_LINK_REDIRECTED:
      return {
        ...state,
        deepLinkRedirected: true,
      };
    default:
      return state;
  }
};

const pathReducer = combineReducers({
  deepLinking,
});

export default pathReducer;
