import { combineReducers } from "redux";
import { trackUserEvent } from "utils/tracking";

import {
  ADD_PAYMENT,
  CLEAR_USER_CARD_DATA,
  CLEAR_USER_DATA,
  CONTACT_CUSTOMER_SERVICE,
  CREATE_CARD_TOKEN,
  CREATE_STRIPE_ERROR,
  DELETE_PAYMENT,
  EDIT_PAYMENT,
  GET_USER,
  RESET_CONTACT_FORM,
  SELECT_PAYMENT_DEFAULT,
  SELECT_USER_LOCATION,
  TOGGLE_ADD_NEW_CARD,
  TOGGLE_CARD_FORM,
  TOGGLE_MAINTENANCE_NOTIFICATION,
  USER_INITIALIZED,
} from "constants/user";

const initialContactCustomerServiceState = {
  errorMessage: "",
  isLoading: false,
  isMessageSent: false,
};

const contactCustomerService = (state = initialContactCustomerServiceState, action) => {
  switch (action.type) {
    case CONTACT_CUSTOMER_SERVICE.PENDING:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
      };

    case CONTACT_CUSTOMER_SERVICE.SUCCESS:
      return {
        ...state,
        isLoading: false,
        errorMessage: false,
        isMessageSent: true,
      };
    case CONTACT_CUSTOMER_SERVICE.FAILURE:
      return {
        ...state,
        isLoading: false,
        errorMessage: action.error,
      };
    case RESET_CONTACT_FORM:
      return initialContactCustomerServiceState;
    default:
      return state;
  }
};

const initialUserDataRequestState = {
  error: undefined,
  isInitialized: false,
  isLoading: false,
  isGeneratingStripeToken: false,
  userData: {
    PaymentSources: [],
    isAddNewCardOpen: false,
    maintenanceNotificationOpen: false,
  },
};

const userDataRequest = (state = initialUserDataRequestState, action) => {
  switch (action.type) {
    case CLEAR_USER_DATA: {
      return {
        ...state,
        ...initialUserDataRequestState,
      };
    }

    case CLEAR_USER_CARD_DATA: {
      const PaymentSources = state.userData.PaymentSources.map(paymentSource => ({
        ...paymentSource,
        errorMessage: "",
        isLoading: false,
        isOpen: false,
      }));

      return {
        ...state,
        error: undefined,
        isInitialized: true,
        isLoading: false,
        isGeneratingStripeToken: false,
        userData: { ...state.userData, PaymentSources, isAddNewCardOpen: false },
      };
    }

    case GET_USER.PENDING: {
      return {
        ...state,
        isLoading: true,
        userData: { ...state.userData, PaymentSources: [], locationSelected: undefined },
      };
    }

    case GET_USER.SUCCESS: {
      const paymentSources = action.payload.data.PaymentSources.map(payment => ({
        ...payment,
        isOpen: false,
        isLoading: false,
        errorMessage: "",
      }));
      const paymentDefault = paymentSources.find(payment => payment.IsDefault === true);
      trackUserEvent({
        event: "get-user-data-success",
        "brand-name": action.payload.data.Locations[0].Brand.Name,
        "brand-id": action.payload.data.Locations[0].Brand.Id,
      });
      /* eslint-disable */
      if (typeof zE === "function") {
        zE("webWidget", "identify", {
          name: `${action.payload.data.FirstName} ${action.payload.data.LastName}`,
          email: action.payload.data.Email,
          organization: action.payload.data.Locations[0].Brand.Name,
        });
      }
      /* eslint-enable */
      return {
        ...state,
        isLoading: false,
        userData: {
          ...state.userData,
          ...action.payload.data,
          PaymentSources: paymentSources,
          paymentDefault,
        },
      };
    }

    case GET_USER.FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }

    case TOGGLE_ADD_NEW_CARD:
      return {
        ...state,
        userData: {
          ...state.userData,
          isAddNewCardOpen: !state.userData.isAddNewCardOpen,
        },
      };

    case CREATE_STRIPE_ERROR:
      // On rare instances there's an error generating stripe token,
      // handling it with this message.
      return {
        ...state,
        error: "Stripe error occured. Please, try again after refreshing the page.",
      };

    case SELECT_USER_LOCATION: {
      const previouslySelectedLocation = localStorage.getItem("selectedLocationId");
      // Is previously selected location associated with the current user.
      const isValidLocation = state.userData.Locations.some(
        location => location.Id === previouslySelectedLocation
      );
      // If there is a previously selected location, app is not fully initialized and location is associated with
      // the current user: go ahead and use the location from the localStorage to update the state.
      if (previouslySelectedLocation && !state.isInitialized && isValidLocation) {
        return {
          ...state,
          userData: {
            ...state.userData,
            locationSelected: previouslySelectedLocation,
          },
        };
      }
      // Otherwise save the location id to the localStorage and update the state.
      localStorage.setItem("selectedLocationId", action.locationSelected);
      return {
        ...state,
        userData: {
          ...state.userData,
          locationSelected: action.locationSelected,
        },
      };
    }
    case SELECT_PAYMENT_DEFAULT.SUCCESS: {
      const {
        userData: { PaymentSources },
      } = state;
      const paymentDefaultId = action.meta.previousAction.meta.Id;
      const paymentDefault = PaymentSources.find(payment => payment.Id === paymentDefaultId);
      paymentDefault.IsDefault = true;

      return {
        ...state,
        userData: {
          ...state.userData,
          paymentDefault,
          PaymentSources: state.userData.PaymentSources.map(source => ({
            ...source,
            IsDefault: source.Id === action.meta.previousAction.meta.Id,
          })),
        },
      };
    }

    case CREATE_CARD_TOKEN:
      return { ...state, isGeneratingStripeToken: true };

    case ADD_PAYMENT.PENDING: {
      return { ...state, isLoading: true, isGeneratingStripeToken: false };
    }

    case ADD_PAYMENT.FAILURE: {
      let { message } = action.error.response?.data || {};
      trackUserEvent({
        event: "add-card-error",
        "add-card-route": window.location,
        "add-card-error-message": message,
      });
      // Sometimes the message returned by the backed is an object or an array,
      // in that case we are showing a generic message to avoid breaking the UI.
      if (typeof message !== "string") {
        message = "Invalid card details";
      }
      return { ...state, isLoading: false, error: message };
    }

    case ADD_PAYMENT.SUCCESS: {
      trackUserEvent({
        event: "add-card-success",
        "add-card-route": window.location,
      });
      const {
        userData: { paymentDefault, PaymentSources },
      } = state;
      const newCard = {
        ...action.meta.previousAction.meta,
        Id: action.payload.data.id,
      };

      const newPaymentDefault =
        paymentDefault === undefined ? { ...newCard, IsDefault: true } : paymentDefault;

      return {
        ...state,
        isLoading: false,
        isGeneratingStripeToken: false,
        error: undefined,
        userData: {
          ...state.userData,
          paymentDefault: newPaymentDefault,
          isAddNewCardOpen: false,
          PaymentSources: [...PaymentSources, newCard],
        },
      };
    }

    case TOGGLE_CARD_FORM: {
      const PaymentSources = state.userData.PaymentSources.map(paymentSource => {
        if (paymentSource.Id === action.id) {
          return { ...paymentSource, isOpen: !paymentSource.isOpen };
        }
        return paymentSource;
      });
      return { ...state, userData: { ...state.userData, PaymentSources } };
    }

    case EDIT_PAYMENT.SUCCESS: {
      const { id, zip, expMonth, expYear, name } = action.meta.previousAction.meta;
      const PaymentSources = state.userData.PaymentSources.map(paymentSource => {
        if (paymentSource.Id === id) {
          return {
            ...paymentSource,
            isLoading: false,
            errorMessage: "",
            isOpen: false,
            AddressZip: zip,
            ExpMonth: expMonth,
            ExpYear: expYear,
            Name: name,
          };
        }
        return paymentSource;
      });
      return { ...state, userData: { ...state.userData, PaymentSources } };
    }

    case EDIT_PAYMENT.PENDING: {
      const { id } = action.meta;
      const PaymentSources = state.userData.PaymentSources.map(paymentSource => {
        if (paymentSource.Id === id) {
          return { ...paymentSource, isLoading: true };
        }
        return paymentSource;
      });
      return { ...state, userData: { ...state.userData, PaymentSources } };
    }

    case EDIT_PAYMENT.FAILURE: {
      const { id } = action.meta.previousAction.meta;
      let { message } = action.error.response?.data || {};
      // Sometimes the message returned by the backed is an object or an array,
      // in that case we are showing a generic message to avoid breaking the UI.
      if (typeof message !== "string") {
        message = "Invalid card details";
      }
      const PaymentSources = state.userData.PaymentSources.map(paymentSource => {
        if (paymentSource.Id === id) {
          return { ...paymentSource, isLoading: false, errorMessage: message };
        }
        return paymentSource;
      });
      return { ...state, userData: { ...state.userData, PaymentSources } };
    }

    case DELETE_PAYMENT.SUCCESS: {
      const {
        userData: { paymentDefault },
      } = state;
      const paymentId = action.meta.previousAction.meta.Id;
      const newPaymentDefault = paymentDefault.Id === paymentId ? undefined : paymentDefault;

      return {
        ...state,
        userData: {
          ...state.userData,
          paymentDefault: newPaymentDefault,
          PaymentSources: state.userData.PaymentSources.filter(source => source.Id !== paymentId),
        },
      };
    }

    case USER_INITIALIZED:
      return {
        ...state,
        ...action.payload,
      };

    case TOGGLE_MAINTENANCE_NOTIFICATION:
      return {
        ...state,
        userData: {
          ...state.userData,
          maintenanceNotificationOpen: !state.userData.maintenanceNotificationOpen,
        },
      };

    default:
      return state;
  }
};

const userDataReducer = combineReducers({
  userDataRequest,
  contactCustomerService,
});

export default userDataReducer;
