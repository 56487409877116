import React, { Component } from "react";
import { connect } from "react-redux";
import cn from "classnames";
import { selectUserLocation, clearSelections } from "actions";
import { trackUserEvent } from "../../utils";

class UnitSelector extends Component {
  state = {
    expanded: false,
  };

  selectUnit = (Id, UnitNumber) => () => {
    const { locationSelected } = this.props;

    if (Id !== locationSelected) {
      this.props.selectUserLocation(Id);
      this.props.clearSelections();
      trackUserEvent({
        event: "select-unit",
        "unit-number": UnitNumber,
      });
    }

    this.setState(state => ({
      ...state,
      expanded: !state.expanded,
    }));
  };

  renderLocation = (location, index) => {
    const { locationSelected, locations } = this.props;
    const { City, Id, State, StreetAddress, UnitNumber, Zip, HeroBucks } = location;
    const total = locations.length;

    return (
      <li
        key={Id}
        className={cn("unitItem", { selected: locationSelected === Id })}
        onClick={this.selectUnit(Id, UnitNumber)}
      >
        <ul className="unitItemDetails">
          <li className="unitAndAddress">
            <ul>
              <li className="unitNumber">
                <p>Unit # {UnitNumber}</p>
              </li>
              <li className="address">
                <p>
                  {StreetAddress}
                  <br />
                  {City}, {State} {Zip}
                </p>
              </li>
            </ul>
          </li>
          <li className="balance">
            <p>
              Balance:
              <br />
              <span>${HeroBucks.Balance}</span>
            </p>
          </li>
          <li className="dropdown">
            <div className="text">
              <span className="bold">{index + 1}</span> of {total} Locations
            </div>
          </li>
        </ul>
        <div className="dropdownArrow">
          <div className="icon-container">
            <i className="chevron" />
          </div>
        </div>
      </li>
    );
  };

  render() {
    const { locations } = this.props;
    const { expanded } = this.state;

    return (
      <section className="section-unit-selector noselect">
        <p className="instructions">Select a location</p>
        <ul className={expanded ? "expand" : "collapse"}>{locations.map(this.renderLocation)}</ul>
      </section>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  selectUserLocation: locationId => dispatch(selectUserLocation(locationId)),
  clearSelections: () => dispatch(clearSelections()),
});

export default connect(null, mapDispatchToProps)(UnitSelector);
