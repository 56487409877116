import {
  CLEAR_PLACE_ORDER,
  CLEAR_PROMO_CODE,
  GET_ORDERS_BY_LOCATION,
  GIFT_CARD_ADD,
  GIFT_CARD_REMOVE,
  PLACE_ORDER,
  PROMO_CODE,
  UPDATE_PROMO_CODE,
} from "constants/orders";

import { createID } from "utils";

/**
 * Place an order of an offer size
 * @param {number} locationId
 * @param {string} CampaignSizeId
 * @param {bool} IsAutoRepeat
 * @param {string} StartDate
 */
export const placeOrder = ({
  locationId,
  CampaignLength,
  CampaignSizeId,
  StartDate,
  IsAutoRepeat,
  DiscountCode,
  GiftCards,
}) => ({
  types: [PLACE_ORDER.PENDING, PLACE_ORDER.SUCCESS, PLACE_ORDER.FAILURE],
  payload: {
    request: {
      method: "post",
      url: `locations/${locationId}/orders`,
      data: {
        CampaignSizeId,
        CampaignLength,
        IsAutoRepeat,
        StartDate,
        DiscountCode,
        GiftCards,
      },
    },
  },
});

/**
 * Get all orders by location
 * @param {number} locationId
 */
export const loadOrdersByLocation = locationId => ({
  types: [
    GET_ORDERS_BY_LOCATION.PENDING,
    GET_ORDERS_BY_LOCATION.SUCCESS,
    GET_ORDERS_BY_LOCATION.FAILURE,
  ],
  payload: {
    request: {
      url: `locations/${locationId}/orders`,
    },
  },
});

export const checkPromoCode = ({
  BrandId,
  CampaignLength,
  CampaignSize,
  Code,
  LocationId,
  OfferId,
  StartDate,
  UserId,
  CodeType,
  Id,
}) => {
  return {
    types: [PROMO_CODE.PENDING, PROMO_CODE.SUCCESS, PROMO_CODE.FAILURE],
    payload: {
      request: {
        method: "get",
        url: `promo-codes/get-promo-discount`,
        params: {
          BrandId,
          CampaignLength,
          CampaignSize,
          Code,
          LocationId,
          OfferId,
          StartDate,
          UserId,
          CodeType,
        },
      },
    },
    meta: {
      code: Code,
      codeType: CodeType,
      id: Id,
    },
  };
};

export const updatePromoCode = value => ({
  type: UPDATE_PROMO_CODE,
  value,
});

export const clearPromoCodeError = ({ codeType, id }) => ({
  type: CLEAR_PROMO_CODE,
  payload: { codeType, id },
});

export const clearPlaceOrder = () => ({
  type: CLEAR_PLACE_ORDER,
});

export const addGiftCard = () => ({
  type: GIFT_CARD_ADD,
  giftCard: { isLoading: false, error: "", id: createID() },
});

export const removeGiftCard = id => ({
  type: GIFT_CARD_REMOVE,
  payload: { id },
});
