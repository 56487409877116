import { combineReducers } from "redux";

import {
  DEACTIVATE_ALERTS_BY_LOCATION,
  GET_ALERTS_BY_LOCATION,
  GET_NOT_VIEWED_ALERTS_TOTAL,
} from "constants/alerts";

const initialStateAlertsRequest = {
  error: undefined,
  isLoading: false,
  response: {
    Comp: [],
    Weather: [],
  },
};

const alertsRequest = (state = initialStateAlertsRequest, action) => {
  switch (action.type) {
    case GET_ALERTS_BY_LOCATION.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ALERTS_BY_LOCATION.SUCCESS:
      return {
        ...state,
        error: undefined,
        isLoading: false,
        response: action.payload.data,
      };
    case GET_ALERTS_BY_LOCATION.FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};

const initialStateAlertsTotalRequest = {
  error: undefined,
  isLoading: false,
  response: 0,
};

const alertsTotalRequest = (state = initialStateAlertsTotalRequest, action) => {
  switch (action.type) {
    case GET_NOT_VIEWED_ALERTS_TOTAL.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_NOT_VIEWED_ALERTS_TOTAL.SUCCESS:
      return {
        ...state,
        error: undefined,
        isLoading: false,
        response: action.payload.data.Count,
      };
    case GET_NOT_VIEWED_ALERTS_TOTAL.FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    case DEACTIVATE_ALERTS_BY_LOCATION.SUCCESS:
      return {
        ...state,
        response: state.response - 1,
      };
    default:
      return state;
  }
};

const alertsReducer = combineReducers({
  alertsRequest,
  alertsTotalRequest,
});

export default alertsReducer;
