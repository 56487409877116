import { combineReducers } from "redux";

import { HEALTH_CHECK } from "constants/health";

const initialState = {
  isLoading: false,
  data: undefined,
};

const check = (state = initialState, action) => {
  switch (action.type) {
    case HEALTH_CHECK.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case HEALTH_CHECK.SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    case HEALTH_CHECK.FAILURE:
      return {
        ...state,
        apiHealth: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};

const apiHealthReducer = combineReducers({
  check,
});

export default apiHealthReducer;
