import { CLEAR_LOCATIONS, GET_LOCATIONS } from "constants/locations";

const initialState = {
  all: [],
  error: undefined,
  isLoading: true,
};

const locations = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_LOCATIONS:
      return {
        ...state,
        all: [],
      };
    case GET_LOCATIONS.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_LOCATIONS.SUCCESS:
      return {
        ...state,
        all: action.payload.data.Locations,
        mediaMinimumLeadTime: action.payload.data.MediaMinimumLeadTime,
        isLoading: false,
      };
    case GET_LOCATIONS.FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default locations;
