import { clearPlaceOrder } from "actions";
import {
  SELECT_PROMOTION,
  SELECT_OFFER_SIZE,
  SELECT_OFFER,
  SELECT_SUBCATEGORY,
} from "constants/offers";

export default ({ dispatch, getState }) => next => action => {
  const types = [SELECT_PROMOTION, SELECT_OFFER_SIZE, SELECT_OFFER, SELECT_SUBCATEGORY];

  if (types.includes(action.type)) {
    const {
      orders: {
        campaignLaunch: { hasError },
      },
    } = getState();

    if (hasError) {
      dispatch(clearPlaceOrder());
    }
  }

  return next(action);
};
