import {
  CLEAR_CANCEL_CAMPAIGN,
  CLEAR_TOGGLE_AUTO_REPEAT_CAMPAIGN,
  CANCEL_CAMPAIGN,
  GET_CAMPAIGNS_BY_LOCATION,
  GET_PAST_CAMPAIGNS_BY_LOCATION,
  GET_ACTIVE_CAMPAIGNS_BY_LOCATION,
  GET_FUTURE_CAMPAIGNS_BY_LOCATION,
  GET_PROBLEM_CAMPAIGNS_BY_LOCATION,
  TOGGLE_AUTO_REPEAT_CAMPAIGN,
  GET_CAMPAIGN_REPORT,
  GET_AUTOREPEAT_REPORT,
  GET_INDIVIDUAL_CAMPAIGN_DATA,
} from "constants/campaigns";

export const clearToggleAutoRepeatCampaign = () => ({
  type: CLEAR_TOGGLE_AUTO_REPEAT_CAMPAIGN,
});

/**
 * Get campaigns for location with optional filters and limits
 * @param {number} locationId
 */
export const loadCampaignsByLocation = locationId => ({
  types: [
    GET_CAMPAIGNS_BY_LOCATION.PENDING,
    GET_CAMPAIGNS_BY_LOCATION.SUCCESS,
    GET_CAMPAIGNS_BY_LOCATION.FAILURE,
  ],
  payload: {
    request: {
      url: `locations/${locationId}/campaigns`,
    },
  },
});

/**
 * Get past campaigns for location with optional filters and limits
 * @param {number} locationId
 * @param {object} [query]
 */
export const loadPastCampaignsByLocation = ({ locationId, query = {} }) => ({
  types: [
    GET_PAST_CAMPAIGNS_BY_LOCATION.PENDING,
    GET_PAST_CAMPAIGNS_BY_LOCATION.SUCCESS,
    GET_PAST_CAMPAIGNS_BY_LOCATION.FAILURE,
  ],
  payload: {
    request: {
      url: `locations/${locationId}/campaigns/past`,
      params: query,
    },
  },
  meta: {
    query,
  },
});

/**
 * Get active campaigns for location with optional filters and limits
 * @param {number} locationId
 * @param {object} [query]
 */
export const loadActiveCampaignsByLocation = ({ locationId, query = {} }) => ({
  types: [
    GET_ACTIVE_CAMPAIGNS_BY_LOCATION.PENDING,
    GET_ACTIVE_CAMPAIGNS_BY_LOCATION.SUCCESS,
    GET_ACTIVE_CAMPAIGNS_BY_LOCATION.FAILURE,
  ],
  payload: {
    request: {
      url: `locations/${locationId}/campaigns/active`,
      params: query,
    },
  },
  meta: {
    query,
  },
});

/**
 * Get future campaigns for location with optional filters and limits
 * @param {number} locationId
 * @param {object} [query]
 */
export const loadFutureCampaignsByLocation = ({ locationId, query = {} }) => ({
  types: [
    GET_FUTURE_CAMPAIGNS_BY_LOCATION.PENDING,
    GET_FUTURE_CAMPAIGNS_BY_LOCATION.SUCCESS,
    GET_FUTURE_CAMPAIGNS_BY_LOCATION.FAILURE,
  ],
  payload: {
    request: {
      url: `locations/${locationId}/campaigns/future`,
      params: query,
    },
  },
  meta: {
    query,
  },
});

/**
 * Cancel a campaign that has not yet been ran.
 * @param {number} locationId
 * @param {number} campaignId
 */
export const cancelCampaign = ({ locationId, campaignId }) => ({
  types: [CANCEL_CAMPAIGN.PENDING, CANCEL_CAMPAIGN.SUCCESS, CANCEL_CAMPAIGN.FAILURE],
  payload: {
    request: {
      method: "delete",
      url: `locations/${locationId}/campaigns/${campaignId}`,
    },
  },
  meta: {
    campaignId,
  },
});

/**
 * Cancel a campaign.
 */
export const clearCancelCampaign = () => ({
  type: CLEAR_CANCEL_CAMPAIGN,
});

/**
 * Toggle Auto Repeat for a campaign.
 * @param {number} orderId
 * @param {bool} Active
 */
export const toggleAutoRepeatCampaign = ({ orderId, autoRepeatActive }) => ({
  types: [
    TOGGLE_AUTO_REPEAT_CAMPAIGN.PENDING,
    TOGGLE_AUTO_REPEAT_CAMPAIGN.SUCCESS,
    TOGGLE_AUTO_REPEAT_CAMPAIGN.FAILURE,
  ],
  payload: {
    request: {
      method: "post",
      url: `orders/${orderId}`,
      data: {
        Active: autoRepeatActive,
      },
    },
  },
  meta: {
    orderId,
    autoRepeatActive,
  },
});

/**
 * Get problem campaigns for location with optional filters and limits
 * @param {number} locationId
 * @param {object} [query]
 */
export const loadProblemCampaignsByLocation = ({ locationId, query = {} }) => ({
  types: [
    GET_PROBLEM_CAMPAIGNS_BY_LOCATION.PENDING,
    GET_PROBLEM_CAMPAIGNS_BY_LOCATION.SUCCESS,
    GET_PROBLEM_CAMPAIGNS_BY_LOCATION.FAILURE,
  ],
  payload: {
    request: {
      url: `locations/${locationId}/campaigns/problem`,
      params: query,
    },
  },
  meta: {
    query,
  },
});

export const getCampaignReport = campaignId => ({
  types: [GET_CAMPAIGN_REPORT.PENDING, GET_CAMPAIGN_REPORT.SUCCESS, GET_CAMPAIGN_REPORT.FAILURE],
  payload: {
    client: "reporting",
    request: {
      method: "get",
      url: `/campaigns/${campaignId}`,
    },
  },
  meta: {
    campaignId,
  },
});

export const getAutorepeatReport = (campaignId, urlParamFlag) => ({
  types: [
    GET_AUTOREPEAT_REPORT.PENDING,
    GET_AUTOREPEAT_REPORT.SUCCESS,
    GET_AUTOREPEAT_REPORT.FAILURE,
  ],
  payload: {
    client: "reporting",
    request: {
      method: "get",
      // TODO: add in query param flag => either ${DateView} or ${DailySumView}
      // url: `/view/DateView?campaignId=${campaignId}`,
      // url: `/view/${'DailySumView'}?campaignId=${campaignId}`,
      url: `/view/${urlParamFlag}?campaignId=${campaignId}`,
    },
  },
  meta: {
    campaignId,
  },
});

export const getIndividualCampaignData = referenceNumber => {
  return {
    types: [
      GET_INDIVIDUAL_CAMPAIGN_DATA.PENDING,
      GET_INDIVIDUAL_CAMPAIGN_DATA.SUCCESS,
      GET_INDIVIDUAL_CAMPAIGN_DATA.FAILURE,
    ],
    payload: {
      request: {
        method: "get",
        url: `/campaigns/${referenceNumber}`,
      },
    },
    meta: {
      referenceNumber,
    },
  };
};
