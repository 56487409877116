import { sha256 } from "js-sha256";

import {
  CLEAR_SEND_ACTIVATION_EMAIL,
  FORGOT_PASSWORD,
  LOGIN_REFRESH,
  LOGIN,
  LOGOUT,
  RESET_PASSWORD,
  SET_AUTH_FROM_LOCAL,
  USER_ACTIVATE,
  SEND_ACTIVATION_EMAIL,
  CLEAR_RESET_PASSWORD,
} from "constants/auth";

/**
 * Set auth data from local storage
 * @param {object} data
 * @param {string} data.AccessToken
 * @param {string} data.AccessTokenExpiration
 * @param {string} data.RefreshToken
 * @param {string} data.RefreshTokenExpiration
 * @param {string} data.AppUserGUID
 */
export const setAuthFromLocal = data => ({
  type: SET_AUTH_FROM_LOCAL,
  data,
});

/**
 * Login user
 * @param {object} data
 * @param {string} data.Email
 * @param {string} data.PasswordHash
 */
export const login = ({ Email, PasswordHash }) => ({
  types: [LOGIN.PENDING, LOGIN.SUCCESS, LOGIN.FAILURE],
  payload: {
    request: {
      method: "post",
      url: "auth/login",
      data: {
        Email: Email.toLowerCase(),
        PasswordHash: sha256(PasswordHash),
      },
    },
  },
});

/**
 * Refresh token
 * @param {string} AppUserGUID
 * @param {string} RefreshToken
 */
export const loginRefresh = ({ AppUserGUID, RefreshToken }) => {
  const refreshToken = btoa(`${AppUserGUID}:${RefreshToken}`);

  return {
    types: [LOGIN_REFRESH.PENDING, LOGIN_REFRESH.SUCCESS, LOGIN_REFRESH.FAILURE],
    payload: {
      request: {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
        url: "auth/refresh",
      },
    },
  };
};

/**
 * Logout user
 * @param {string} AppUserGUID
 */
export const logout = AppUserGUID => ({
  types: [LOGOUT.PENDING, LOGOUT.SUCCESS, LOGOUT.FAILURE],
  payload: {
    request: {
      method: "post",
      url: "auth/logout",
      data: {
        AppUserGUID,
      },
    },
  },
});

/**
 * Activate new user with temp token
 * @param {object} data
 * @param {string} data.Email
 * @param {string} data.PasswordHash
 * @param {string} data.TempToken
 * @param {boolean} data.UserAgreementApproved
 */
export const activateUser = ({
  Email,
  PasswordHash,
  TempToken,
  UserAgreementApproved,
  NotificationsEnabled,
}) => ({
  types: [USER_ACTIVATE.PENDING, USER_ACTIVATE.SUCCESS, USER_ACTIVATE.FAILURE],
  payload: {
    request: {
      method: "put",
      url: "auth/activate",
      data: {
        Email: Email.toLowerCase(),
        TempToken,
        PasswordHash: sha256(PasswordHash),
        UserAgreementApproved,
        NotificationsEnabled,
      },
    },
  },
});

/**
 * Forgot password
 * @param {string} Email
 */
export const forgotPassword = Email => ({
  types: [FORGOT_PASSWORD.PENDING, FORGOT_PASSWORD.SUCCESS, FORGOT_PASSWORD.FAILURE],
  payload: {
    request: {
      method: "post",
      url: "auth/password/forgot",
      data: {
        Email: Email.toLowerCase(),
      },
    },
  },
});

/**
 * Reset password
 * @param {string} NewPassword
 * @param {string} TempToken
 */
export const resetPassword = ({ NewPassword, TempToken }) => ({
  types: [RESET_PASSWORD.PENDING, RESET_PASSWORD.SUCCESS, RESET_PASSWORD.FAILURE],
  payload: {
    request: {
      method: "put",
      url: "auth/password/reset",
      data: {
        TempToken,
        NewPasswordHash: sha256(NewPassword),
      },
    },
  },
});

export const sendActivationEmail = ({ Email }) => ({
  types: [
    SEND_ACTIVATION_EMAIL.PENDING,
    SEND_ACTIVATION_EMAIL.SUCCESS,
    SEND_ACTIVATION_EMAIL.FAILURE,
  ],
  payload: {
    request: {
      method: "post",
      url: `/user/${Email.toLowerCase()}/activation/send`,
    },
  },
});

export const clearSendActivationEmail = () => ({
  type: CLEAR_SEND_ACTIVATION_EMAIL,
});

export const clearResetPasswordSuccess = () => ({
  type: CLEAR_RESET_PASSWORD,
});
