import { GET_BRAND_COPY } from "constants/campaignSections";

export const getBrandCopy = brandId => {
  return {
    types: [GET_BRAND_COPY.PENDING, GET_BRAND_COPY.SUCCESS, GET_BRAND_COPY.FAILURE],
    payload: {
      request: {
        url: `brands/${brandId}/content`,
      },
    },
  };
};
