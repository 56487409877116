import { selectUserLocation } from "actions";
import { GET_LOCATIONS } from "constants/locations";

export default ({ dispatch, getState }) => next => action => {
  if (action.type === GET_LOCATIONS.SUCCESS) {
    const state = getState();
    const locations = action.payload.data.Locations;

    if (locations.length === 0) {
      return next(action);
    }

    // Select first location by default
    const locationId = action.payload.data.Locations[0].Id;
    const { locationSelected } = state.user.userDataRequest.userData;

    if (locationId && locationSelected === undefined) {
      dispatch(selectUserLocation(locationId));
    }
  }

  return next(action);
};
