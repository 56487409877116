import If from "components/conditions/If";
import React from "react";
import { connect } from "react-redux";

const Footer = ({ path }) => {
  const isContactPage = path === "/contact";
  const isLoginPage = path === "/login";

  const legalCopy = `Copyright ©️ ${new Date().getFullYear()} Local Hero, All rights reserved.`;

  return (
    <footer className={`${isContactPage && "gray"}`}>
      <p className="footer-legal">
        <If condition={!isLoginPage}>
          <a href="/terms-of-use" target="_blank">
            Terms of Use
          </a>
          <br />
        </If>
        {legalCopy}
      </p>
    </footer>
  );
};

function mapState(appState) {
  return {
    path: appState.router.location.pathname,
  };
}

export default connect(mapState)(Footer);
