import { combineReducers } from "redux";
import {
  FORGOT_PASSWORD,
  LOGIN_REFRESH,
  LOGIN,
  LOGOUT,
  RESET_PASSWORD,
  SET_AUTH_FROM_LOCAL,
  USER_ACTIVATE,
  SEND_ACTIVATION_EMAIL,
  CLEAR_SEND_ACTIVATION_EMAIL,
  CLEAR_RESET_PASSWORD,
} from "constants/auth";

const initialStateActivateCustomerUser = {
  isLoading: false,
  error: undefined,
  response: undefined,
};

const activateCustomerUser = (state = initialStateActivateCustomerUser, action) => {
  switch (action.type) {
    case USER_ACTIVATE.PENDING:
      return {
        ...state,
        isLoading: true,
      };

    case USER_ACTIVATE.SUCCESS:
      return {
        ...state,
        error: undefined,
        isLoading: false,
        response: action.payload.data,
      };

    case USER_ACTIVATE.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

const initialStateLogin = {
  isLoading: false,
  authData: {},
  error: undefined,
};

const login = (state = initialStateLogin, action) => {
  switch (action.type) {
    case LOGIN.PENDING:
      if (Storage !== undefined) {
        localStorage.removeItem("customerAccessToken");
        localStorage.removeItem("customerAccessTokenExpiration");
        localStorage.removeItem("customerRefreshToken");
        localStorage.removeItem("customerRefreshTokenExpiration");
        localStorage.removeItem("customerAppUserGUID");
      }
      return {
        ...state,
        isLoading: true,
        authData: {
          ...state.authData,
          AccessToken: false,
          AccessTokenExpiration: false,
          RefreshToken: false,
          RefreshTokenExpiration: false,
          AppUserGUID: false,
        },
      };

    case LOGIN.SUCCESS: {
      const {
        AccessToken,
        AccessTokenExpiration,
        AppUserGUID,
        RefreshToken,
        RefreshTokenExpiration,
      } = action.payload.data;

      if (Storage !== undefined) {
        localStorage.setItem("customerAccessToken", AccessToken);
        localStorage.setItem("customerAccessTokenExpiration", AccessTokenExpiration);
        localStorage.setItem("customerRefreshToken", RefreshToken);
        localStorage.setItem("customerRefreshTokenExpiration", RefreshTokenExpiration);
        localStorage.setItem("customerAppUserGUID", AppUserGUID);
      }

      return {
        ...state,
        error: undefined,
        isLoading: false,
        authData: {
          ...state.authData,
          ...action.payload.data,
        },
      };
    }

    case LOGIN_REFRESH.PENDING:
      return {
        ...state,
        isLoading: true,
      };

    case LOGIN_REFRESH.SUCCESS: {
      const {
        AccessToken,
        AccessTokenExpiration,
        RefreshToken,
        RefreshTokenExpiration,
        AppUserGUID,
      } = action.payload.data;

      if (Storage !== undefined) {
        localStorage.setItem("customerAccessToken", AccessToken);
        localStorage.setItem("customerAccessTokenExpiration", AccessTokenExpiration);
        localStorage.setItem("customerRefreshToken", RefreshToken);
        localStorage.setItem("customerRefreshTokenExpiration", RefreshTokenExpiration);
        localStorage.setItem("customerAppUserGUID", AppUserGUID);
      }

      const authData = {
        ...state.authData,
        ...action.payload.data,
      };

      return {
        ...state,
        error: undefined,
        isLoading: false,
        authData,
      };
    }

    case LOGIN.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case SET_AUTH_FROM_LOCAL:
      return {
        ...state,
        authData: {
          ...state.authData,
          ...action.data,
        },
      };

    case LOGIN_REFRESH.FAILURE:
    case LOGOUT.SUCCESS: {
      if (Storage !== undefined) {
        localStorage.removeItem("customerAccessToken");
        localStorage.removeItem("customerAccessTokenExpiration");
        localStorage.removeItem("customerRefreshToken");
        localStorage.removeItem("customerRefreshTokenExpiration");
        localStorage.removeItem("customerAppUserGUID");
      }

      return {
        ...state,
        error: action.error,
        isLoading: false,
        authData: {
          ...state.authData,
          AccessToken: false,
          AccessTokenExpiration: false,
          RefreshToken: false,
          RefreshTokenExpiration: false,
          AppUserGUID: false,
        },
      };
    }

    default:
      return state;
  }
};

const initialStateLogout = {
  error: undefined,
  isLoading: false,
  response: undefined,
};

const logout = (state = initialStateLogout, action) => {
  switch (action.type) {
    case LOGOUT.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case LOGOUT.SUCCESS: {
      return {
        ...state,
        error: undefined,
        isLoading: false,
        response: action.payload.data,
      };
    }
    case LOGOUT.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

const initialStateForgotPassword = {
  error: undefined,
  isLoading: false,
  response: undefined,
};

const forgotPassword = (state = initialStateForgotPassword, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case FORGOT_PASSWORD.SUCCESS: {
      return {
        ...state,
        error: undefined,
        isLoading: false,
        response: action.payload.data,
      };
    }
    case FORGOT_PASSWORD.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

const initialStateResetPassword = {
  error: undefined,
  isLoading: false,
  response: undefined,
};

const resetPassword = (state = initialStateResetPassword, action) => {
  switch (action.type) {
    case RESET_PASSWORD.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case RESET_PASSWORD.SUCCESS: {
      return {
        ...state,
        error: undefined,
        isLoading: false,
        response: action.payload.data,
      };
    }
    case RESET_PASSWORD.FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    case CLEAR_RESET_PASSWORD:
      return initialStateResetPassword;
    default:
      return state;
  }
};

const initialStateSendActivation = {
  isSendingActivation: false,
  sendActivationError: undefined,
  sendActivationSuccess: false,
};

const sendActivationEmail = (state = initialStateSendActivation, action) => {
  switch (action.type) {
    case SEND_ACTIVATION_EMAIL.PENDING:
      return {
        ...state,
        sendActivationError: undefined,
        isSendingActivation: true,
        sendActivationSuccess: false,
      };

    case SEND_ACTIVATION_EMAIL.SUCCESS: {
      return {
        ...state,
        sendActivationError: undefined,
        isSendingActivation: false,
        sendActivationSuccess: true,
      };
    }

    case SEND_ACTIVATION_EMAIL.FAILURE:
      return {
        ...state,
        isSendingActivation: false,
        sendActivationSuccess: false,
        sendActivationError: action.error,
      };

    case CLEAR_SEND_ACTIVATION_EMAIL:
      return {
        ...state,
        isSendingActivation: false,
        sendActivationError: undefined,
        sendActivationSuccess: false,
      };

    default:
      return state;
  }
};

const authReducer = combineReducers({
  activateCustomerUser,
  forgotPassword,
  login,
  logout,
  resetPassword,
  sendActivationEmail,
});

export default authReducer;
