import { asyncActionType } from "utils/constants";

export const SET_AUTH_FROM_LOCAL = "SET_AUTH_FROM_LOCAL";
export const LOGIN = asyncActionType("LOGIN");
export const LOGIN_REFRESH = asyncActionType("LOGIN_REFRESH");
export const LOGOUT = asyncActionType("LOGOUT");
export const USER_ACTIVATE = asyncActionType("USER_ACTIVATE");
export const FORGOT_PASSWORD = asyncActionType("FORGOT_PASSWORD");
export const RESET_PASSWORD = asyncActionType("RESET_PASSWORD");
export const SEND_ACTIVATION_EMAIL = asyncActionType("SEND_ACTIVATION_EMAIL");
export const CLEAR_SEND_ACTIVATION_EMAIL = "CLEAR_SEND_ACTIVATION_EMAIL";
export const CLEAR_RESET_PASSWORD = "CLEAR_RESET_PASSWORD";
