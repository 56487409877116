/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { ICONS } from "constants/assets";
import { logout, toggleMaintenanceNotification, clearLocations, clearUserData } from "actions";
import InlineSVG from "../inline-svg/InlineSVG";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileMenuOpen: false,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleScreenResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleScreenResize);
  }

  toggleMobileMenu = e => {
    e.stopPropagation();
    this.setState(state => ({
      mobileMenuOpen: !state.mobileMenuOpen,
    }));
  };

  closeMobileMenu = () => {
    this.setState({
      mobileMenuOpen: false,
    });
  };

  handleScreenResize = () => {
    if (this.state.mobileMenuOpen) {
      this.closeMobileMenu();
    }
  };

  handleWindowClick = () => {
    if (this.state.mobileMenuOpen) {
      this.closeMobileMenu();
    }
  };

  handleLogout = () => {
    const { guid } = this.props;
    this.props.logout(guid);
  };

  render() {
    let brandLogo = "";
    if (
      this.props.locationSelected &&
      this.props.locationSelected !== "" &&
      this.props.locations &&
      this.props.locations.length > 0
    ) {
      for (let i = 0; i < this.props.locations.length; i++) {
        if (
          this.props.locations[i].Brand &&
          this.props.locations[i].Id === this.props.locationSelected &&
          this.props.locations[i].Brand.Assets &&
          this.props.locations[i].Brand.Assets.MainLogo &&
          this.props.locations[i].Brand.Assets.MainLogo.URL
        ) {
          brandLogo = this.props.locations[i].Brand.Assets.MainLogo.URL;
          break;
        }
      }
    }

    return (
      <>
        <header>
          <div className="headerInner">
            <div className="headerLogoContainer">
              <div className="siteLogo">
                <img className="logo-platform" src="/assets/images/ui/logo-header-local-hero.png" />
                {this.props.isLoggedIn && brandLogo !== "" && (
                  <img className="logo-brand" src={brandLogo} />
                )}
                {this.props.isLoggedIn && <Link onClick={this.closeMobileMenu} to="/" />}
              </div>
            </div>
            {this.props.isLoggedIn && (
              <nav>
                <div className="mobileNavLink">
                  <NavLink activeClassName="active" onClick={this.closeMobileMenu} to="/account">
                    <InlineSVG svgSource="/assets/images/ui/icon-nav-mobile-account.svg" />
                  </NavLink>
                </div>
                <div
                  className={`mobileNavToggle${this.state.mobileMenuOpen ? " active" : ""}`}
                  onClick={this.toggleMobileMenu}
                >
                  <InlineSVG svgSource="/assets/images/ui/icon-nav-mobile-menu.svg" />
                </div>
                <div
                  className={`mobileNavOverlay${this.state.mobileMenuOpen ? " visible" : ""}`}
                  onClick={this.closeMobileMenu}
                />
                <ul className={this.state.mobileMenuOpen ? "open" : "closed"}>
                  <li className="mobileHide">
                    <NavLink activeClassName="active" onClick={this.closeMobileMenu} to="/account">
                      My account
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      onClick={this.closeMobileMenu}
                      to="/how-to-use"
                    >
                      How to use Local Hero
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" onClick={this.closeMobileMenu} to="/contact">
                      Contact us
                    </NavLink>
                  </li>
                  <li className="legal">
                    <NavLink
                      activeClassName="active"
                      onClick={this.closeMobileMenu}
                      to="/terms-of-use"
                    >
                      Terms of Use
                    </NavLink>
                  </li>
                  <li className="legal">
                    <NavLink
                      activeClassName="active"
                      onClick={this.closeMobileMenu}
                      to="/privacy-policy"
                    >
                      Privacy Policy
                    </NavLink>
                  </li>
                  <li>
                    <a onClick={this.handleLogout}>Logout</a>
                  </li>
                </ul>
                <div
                  className={`mobileNavCloseButton${this.state.mobileMenuOpen ? " visible" : ""}`}
                  onClick={this.closeMobileMenu}
                >
                  <span className="closeX" />
                </div>
                <div className={`mobileNavPoweredBy${this.state.mobileMenuOpen ? " visible" : ""}`}>
                  <p>
                    powered by
                    <br />
                    <span>Local Hero</span>
                  </p>
                </div>
              </nav>
            )}
          </div>
        </header>
        <div
          className={`maintenance-message ${
            this.props.maintenanceNotificationOpen ? "visible" : ""
          }`}
        >
          <p>
            Local Hero is getting an upgrade! From 9:00am to 2:00pm Eastern on September 8, 2021,
            the app will be unavailable as we'll be hard at work making the Local Hero experience
            even better. All active campaigns will continue to be up and running.
          </p>
          <button
            className="close-maintenance-message"
            onClick={this.props.toggleMaintenanceNotification}
            type="button"
          >
            <img alt="close_notificaiton" src={ICONS.CLOSE_WHITE} />
          </button>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    guid: state.auth.login.authData.AppUserGUID,
    locationSelected: state.user.userDataRequest.userData.locationSelected,
    locations: state.locations.all,
    maintenanceNotificationOpen: state.user.userDataRequest.userData.maintenanceNotificationOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    clearLocations: () => dispatch(clearLocations()),
    clearUserData: () => dispatch(clearUserData()),
    logout: guid => dispatch(logout(guid)),
    toggleMaintenanceNotification: () => dispatch(toggleMaintenanceNotification()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
