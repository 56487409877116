import React, { Component, lazy, Suspense } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import TagManager from "react-gtm-module";

import isEmpty from "lodash/isEmpty";

import "./App.scss";

import Header from "components/header/Header";
import Footer from "components/footer/Footer";
import LocationNav from "components/location-nav/LocationNav";
import UnitSelector from "components/unit-selector/UnitSelector";

import { setDeepLinkPath } from "actions";

import { trackUserEvent } from "./utils";

const Account = lazy(() => import("./containers/account/Account"));
const ActivateCustomerUser = lazy(() => import("./containers/activate/ActivateCustomerUser"));
const Alerts = lazy(() => import("./containers/alerts/Alerts"));
const Contact = lazy(() => import("./containers/contact/Contact"));
const DigitalMedia = lazy(() => import("./containers/landing-pages/DigitalMedia"));
const ForgotPassword = lazy(() => import("./containers/forgot-password/ForgotPassword"));
const Home = lazy(() => import("./containers/home/Home"));
const HowItWorks = lazy(() => import("./containers/how-it-works/HowItWorks"));
const IndividualCampaign = lazy(() =>
  import("./containers/individual-campaign/IndividualCampaign")
);
const Login = lazy(() => import("./containers/login/Login"));
const PrivacyPolicy = lazy(() => import("./containers/legal/PrivacyPolicy"));
const ResetPassword = lazy(() => import("./containers/reset-password/ResetPassword"));
const Start = lazy(() => import("./containers/start/Start"));
const Status = lazy(() => import("./containers/status/Status"));
const TermsOfUse = lazy(() => import("./containers/legal/TermsOfUse"));
const TermsTracfoneDealerLoyaltyProgram = lazy(() =>
  import("./containers/terms-tracfone-dealer-loyalty-program/TermsTracfoneDealerLoyaltyProgram")
);
const ThankYou = lazy(() => import("./containers/thank-you/ThankYou"));

const tagManagerArgs = {
  gtmId: "GTM-P26KNQM",
};
if (process.env.REACT_APP_ENVIRONMENT === "PRODUCTION") {
  TagManager.initialize(tagManagerArgs);
}

function LoginRedirect({ location: { pathname }, ...props }) {
  if (
    pathname &&
    pathname !== undefined &&
    pathname !== "" &&
    pathname !== "/" &&
    pathname.length > 1
  ) {
    const deepLinkData = {
      pathname,
      access: {},
    };
    props.setDeepLinkPath(deepLinkData);
  }

  return (
    <div>
      <Redirect to="/login" />
      <main>
        <div className="contentInner">
          <p>You must be logged in to use Local Hero.</p>
        </div>
      </main>
    </div>
  );
}

function CaptureAllURLs({ location }) {
  return (
    <main>
      <div className="contentInner">
        <h3>No match for {location.pathname}</h3>
      </div>
    </main>
  );
}

const Loader = <div className="splashOverlay">Loading...</div>;

class App extends Component {
  state = {
    stripe: null,
    chatIframeMouseOver: false,
  };

  componentDidMount = () => {
    this.initStripe();
    setTimeout(this.initChatListener, 5000);
  };

  initStripe = () => {
    loadStripe(process.env.REACT_APP_STRIPE_API_KEY)
      .then(stripe => {
        this.setState({ stripe });
      })
      .catch(err => {
        // May need some better error handling
        console.log("Error with loadStripe:", err);
      });
  };

  initChatListener = () => {
    window.addEventListener("blur", this.windowBlur);

    if (document.getElementById("webWidget")) {
      document.getElementById("webWidget").addEventListener("mouseover", this.chatMouseOver);
      document.getElementById("webWidget").addEventListener("mouseout", this.chatMouseOut);

      if (document.getElementById("launcher")) {
        document.getElementById("launcher").addEventListener("mouseover", this.chatMouseOver);
        document.getElementById("launcher").addEventListener("mouseout", this.chatMouseOut);
      }

      /* eslint-disable */
      if (
        this.props.userData?.FirstName &&
        this.props.userData?.LastName &&
        this.props.userData?.Email &&
        this.props.userData?.Locations[0]?.Brand?.Name &&
        typeof zE === "function"
      ) {
        zE("webWidget", "identify", {
          name: `${this.props.userData.FirstName} ${this.props.userData.LastName}`,
          email: this.props.userData.Email,
          organization: this.props.userData.Locations[0].Brand.Name,
        });
      }
      /* eslint-enable */
    } else {
      setTimeout(this.initChatListener, 5000);
    }
  };

  chatMouseOver = () => {
    this.setState({
      chatIframeMouseOver: true,
    });
  };

  chatMouseOut = () => {
    this.setState({
      chatIframeMouseOver: false,
    });
  };

  windowBlur = () => {
    if (this.state.chatIframeMouseOver) {
      trackUserEvent({
        event: "click-chat",
      });
    }
  };

  render() {
    const {
      auth: {
        login: { authData },
      },
      location: { pathname },
      locations,
      locationSelected,
    } = this.props;
    const isLegalPage = pathname === "/terms-of-use" || pathname === "/privacy-policy";
    const isLoggedIn = !isEmpty(authData) && authData.AccessToken;

    // Routes viewable when logged in.
    if (isLoggedIn) {
      return (
        <Elements
          options={{
            fonts: [
              {
                family: "Poppins",
                cssSrc: "https://fonts.googleapis.com/css?family=Poppins&display=swap",
              },
            ],
          }}
          stripe={this.state.stripe}
        >
          <div className="appContainer">
            {/* Show the header if user is logged in and not on a legal page */}
            <Header isLoggedIn={isLoggedIn && !isLegalPage} />
            <main
              className={`${
                this.props.maintenanceNotificationOpen ? "" : "noMaintenanceNotification"
              }`}
            >
              <Suspense fallback={Loader}>
                <Switch>
                  <Route component={Home} exact path="/" />
                  <Route exact path={["/start", "/status", "/alerts"]}>
                    <UnitSelector {...{ locations, locationSelected }} />
                    <LocationNav />
                    <Switch>
                      <Route component={Start} path="/start" />
                      <Route component={Status} path="/status" />
                      <Route component={Alerts} path="/alerts" />
                    </Switch>
                  </Route>
                  <Route component={IndividualCampaign} exact path="/status/:campaignNumber" />
                  <Route component={ThankYou} exact path="/thank-you" />
                  <Route component={Account} exact path="/account" />
                  <Route component={HowItWorks} exact path="/how-to-use" />
                  <Route component={Contact} exact path="/contact" />
                  <Route component={Home} exact path="/login" />
                  <Route component={PrivacyPolicy} exact path="/privacy-policy" />
                  <Route component={TermsOfUse} exact path="/terms-of-use" />
                  <Route
                    component={TermsTracfoneDealerLoyaltyProgram}
                    exact
                    path="/terms-tracfone-dealer-loyalty-program"
                  />
                  <Route component={DigitalMedia} path="/digital-media" />
                  <Route component={CaptureAllURLs} />
                </Switch>
              </Suspense>
            </main>
            <Footer />
          </div>
        </Elements>
      );
    }

    // Routes viewable when NOT logged in. TODO: combine routes that show in both states logged in/out.
    return (
      <div>
        <Header isLoggedIn={isLoggedIn} />
        <Suspense fallback={Loader}>
          <Switch>
            <Route component={ActivateCustomerUser} exact path="/activate" />
            <Route component={Login} exact path="/login" />
            <Route component={ForgotPassword} path="/forgot-password" />
            <Route component={ResetPassword} path="/reset-password" />
            <Route
              component={TermsTracfoneDealerLoyaltyProgram}
              exact
              path="/terms-tracfone-dealer-loyalty-program"
            />
            <Route
              render={props => (
                <LoginRedirect {...props} setDeepLinkPath={this.props.setDeepLinkPath} />
              )}
            />
          </Switch>
        </Suspense>
        <Footer />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setDeepLinkPath: pathname => dispatch(setDeepLinkPath(pathname)),
  };
}

const mapStateToProps = state => ({
  auth: state.auth,
  locations: state.locations.all,
  locationSelected: state.user.userDataRequest.userData.locationSelected,
  userData: state.user.userDataRequest.userData,
  maintenanceNotificationOpen: state.user.userDataRequest.userData.maintenanceNotificationOpen,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));
