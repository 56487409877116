import { useMemo } from "react";
import { format, parse } from "date-fns";

const API_DATE_FORMAT = "yyyy-MM-dd HH:mm:ss";
const DATE_FORMAT = "MM/dd/yyyy";

/**
 * Get date from API and return Date object and formated string
 * @param {string} date For example 2019-12-23 00:00:00
 * @param {object} options For example { format: "EEEE, MMMM d" }
 */
const useDateFormat = (date, options = { format: DATE_FORMAT }) => {
  const parsed = useMemo(() => parse(date, API_DATE_FORMAT, new Date()), [date]);
  const formated = useMemo(() => format(parsed, options.format), [options.format, parsed]);

  return {
    formated,
    parsed,
  };
};

export default useDateFormat;
