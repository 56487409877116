import { asyncActionType } from "utils/constants";

export const ADD_PAYMENT = asyncActionType("ADD_PAYMENT");
export const CLEAR_USER_CARD_DATA = "CLEAR_USER_CARD_DATA";
export const CLEAR_USER_DATA = "CLEAR_USER_DATA";
export const CONTACT_CUSTOMER_SERVICE = asyncActionType("CONTACT_CUSTOMER_SERVICE");
export const CREATE_CARD_TOKEN = "CREATE_CARD_TOKEN";
export const CREATE_STRIPE_ERROR = "CREATE_STRIPE_ERROR";
export const DELETE_PAYMENT = asyncActionType("DELETE_PAYMENT");
export const EDIT_PAYMENT = asyncActionType("EDIT_PAYMENT");
export const GET_USER = asyncActionType("GET_USER");
export const RESET_CONTACT_FORM = "RESET_CONTACT_FORM";
export const SELECT_PAYMENT_DEFAULT = asyncActionType("SELECT_PAYMENT_DEFAULT");
export const SELECT_USER_LOCATION = "SELECT_USER_LOCATION";
export const TOGGLE_ADD_NEW_CARD = "TOGGLE_ADD_NEW_CARD";
export const TOGGLE_CARD_FORM = "TOGGLE_CARD_FORM";
export const TOGGLE_MAINTENANCE_NOTIFICATION = "TOGGLE_MAINTENANCE_NOTIFICATION";
export const USER_INITIALIZED = "USER_INITIALIZED";
