import { combineReducers } from "redux";

import { GET_BRAND_COPY } from "constants/campaignSections";

const initialState = {
  getStarted: {
    headingPart1: "Hello",
    punctuation: "!",
    subheading: "It’s never been easier to promote your business and boost your sales!",
    instructions: "Complete these 5 easy steps and start advertising to local customers.",
    buttonGetStarted: "Get Started",
    sectionRef: "campaignGetStartedSectionRef",
  },
  continue: {
    heading: "",
    subheading:
      "You have started making selections. Would you like to continue where you left off?",
    buttonStartOver: "Start Over",
    buttonContinue: "Continue",
  },
  promotion: {
    stepNumber: 1,
    headingPart1: "Pick Your Promotion",
    headingPart2: "",
    subheading: "Click an icon to start",
    instructions: "Now let’s get down to promoting your business.",
    isHidden: true,
    sectionRef: "campaignPromotionsSectionRef",
  },
  subcategory: {
    heading: "",
    subheading: "Select the daypart.",
    isHidden: true,
    sectionRef: "campaignSubcategorySectionRef",
  },
  offer: {
    stepNumber: 2,
    headingPart1: "Choose Your Local Offer",
    headingPart2: "",
    subheading: "Click the offer you want to run",
    instructions: "Choose the ad you want to put in front of thousands of local customers.",
    isHidden: true,
    sectionRef: "campaignOffersSectionRef",
  },
  size: {
    stepNumber: 3,
    headingPart1: "Select Your Campaign Size",
    subheading: "Pick a size to increase your sales",
    instructions: "The larger the campaign size, the more customers will see your local ad.",
    isHidden: true,
    sectionRef: "campaignSizeSectionRef",
  },
  schedule: {
    stepNumber: 4,
    headingPart1: "Schedule Your Campaign",
    headingPart2: "",
    subheading: "Click to choose a start date",
    instructions:
      "The longer your ads run, the more people see them...and more people will come to your business — it’s a proven fact!",
    isHidden: true,
    sectionRef: "campaignScheduleSectionRef",
  },
  summary: {
    stepNumber: 5,
    headingPart1: "Review & Pay",
    headingPart2: "",
    subheading: "Just one more step!",
    instructions:
      "Review your order below and if it looks good, launch and reach thousands of local customers!",
    isHidden: true,
    sectionRef: "campaignSummarySectionRef",
  },
  summaryDetails: {
    heading: "Details",
    textCampaignLength: "Length",
    textSize: "Size",
    textRepeat: "Repeat",
    textPriceCadence: "per week",
    isHidden: true,
    sectionRef: "campaignSummaryDetailsSectionRef",
  },
  payment: {
    heading: "Select Payment Method",
    textAddNewCard: "Add a new card",
    isHidden: true,
    sectionRef: "campaignPaymentSectionRef",
  },
  launch: {
    buttonTextDefault: "Launch Campaign!",
    buttonTextLaunching: "Launching! ...",
    isHidden: true,
    sectionRef: "campaignLaunch",
  },
};

const campaignSectionsContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BRAND_COPY.SUCCESS: {
      const content = action.payload.data.Data;
      // Merge existing content sections with new ones.
      const getStarted = { ...state.getStarted, ...content.getStarted };
      const offer = {
        ...state.offer,
        ...content.offer,
      };
      const promotion = {
        ...state.promotion,
        ...content.promotion,
      };
      const schedule = {
        ...state.schedule,
        ...content.schedule,
      };
      const size = {
        ...state.size,
        ...content.size,
      };
      const summary = {
        ...state.summary,
        ...content.summary,
      };
      return { ...state, getStarted, offer, promotion, schedule, size, summary };
    }
    default:
      return state;
  }
};

const campaignSectionsContent = combineReducers({
  campaignSectionsContentReducer,
});

export default campaignSectionsContent;
