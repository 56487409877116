import { createStore, compose, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import axios from "axios";
import { multiClientMiddleware } from "redux-axios-middleware";
import { createBrowserHistory } from "history";
import { routerMiddleware as reactRouterMiddleware } from "connected-react-router";

import {
  authMiddleware,
  campaignSelectMiddleware,
  locationsMiddleware,
  routerMiddleware,
  userMiddleware,
  stripeTokenMiddleware,
} from "middlewares";
import rootReducer from "reducers";

export const history = createBrowserHistory();

const loggerMiddleware = createLogger();
// Use tracer only in the dev mode.
const composeEnhancer =
  process.env.REACT_APP_ENVIRONMENT === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })
    : compose;

const clients = {
  default: {
    client: axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    }),
  },
  reporting: {
    client: axios.create({
      baseURL: process.env.REACT_APP_REPORTING_API_URL,
    }),
    v2: {
      client: axios.create({
        baseURL: process.env.REACT_APP_V2_API_URL,
      }),
    },
  },
};

const axiosMiddlewareConfig = {
  interceptors: {
    request: [
      ({ getState /* dispatch, getSourceAction */ }, req) => {
        const {
          auth: {
            login: {
              authData: { AccessToken, AppUserGUID },
            },
          },
        } = getState();
        req.headers["Content-Type"] = "application/json";

        const hasAuthorizationHeader = req.url.endsWith("auth/refresh");

        if (AccessToken && !hasAuthorizationHeader) {
          const token = btoa(`${AppUserGUID}:${AccessToken}`);
          req.headers.Authorization = `Bearer ${token}`;
        }

        return req;
      },
    ],
  },
};

export default function configureStore(initialState) {
  const store = createStore(
    rootReducer(history),
    initialState,
    composeEnhancer(
      applyMiddleware(
        reactRouterMiddleware(history),
        thunkMiddleware,
        multiClientMiddleware(clients, axiosMiddlewareConfig),
        loggerMiddleware,
        authMiddleware,
        campaignSelectMiddleware,
        locationsMiddleware,
        routerMiddleware,
        userMiddleware,
        stripeTokenMiddleware
      )
    )
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers", () => {
      const nextRootReducer = require("../reducers").default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
