import { asyncActionType } from "utils/constants";

export const CLEAR_CANCEL_CAMPAIGN = "CLEAR_CANCEL_CAMPAIGN";
export const CANCEL_CAMPAIGN = asyncActionType("CANCEL_CAMPAIGN");
export const CLEAR_TOGGLE_AUTO_REPEAT_CAMPAIGN = "CLEAR_TOGGLE_AUTO_REPEAT_CAMPAIGN";
export const GET_CAMPAIGNS_BY_LOCATION = asyncActionType("GET_CAMPAIGNS_BY_LOCATION");
export const GET_PAST_CAMPAIGNS_BY_LOCATION = asyncActionType("GET_PAST_CAMPAIGNS_BY_LOCATION");
export const GET_ACTIVE_CAMPAIGNS_BY_LOCATION = asyncActionType("GET_ACTIVE_CAMPAIGNS_BY_LOCATION");
export const GET_FUTURE_CAMPAIGNS_BY_LOCATION = asyncActionType("GET_FUTURE_CAMPAIGNS_BY_LOCATION");
export const GET_PROBLEM_CAMPAIGNS_BY_LOCATION = asyncActionType(
  "GET_PROBLEM_CAMPAIGNS_BY_LOCATION"
);
export const TOGGLE_AUTO_REPEAT_CAMPAIGN = asyncActionType("TOGGLE_AUTO_REPEAT_CAMPAIGN");
export const GET_CAMPAIGN_REPORT = asyncActionType("GET_CAMPAIGN_REPORT");
export const GET_AUTOREPEAT_REPORT = asyncActionType("GET_AUTOREPEAT_REPORT");
export const GET_INDIVIDUAL_CAMPAIGN_DATA = asyncActionType("GET_INDIVIDUAL_CAMPAIGN_DATA");
