import React from "react";
import SVG from "react-inlinesvg";

const InlineSVG = props => {
  return (
    <div className="inlineSVG">
      <SVG className="inlineSVGWrapper" src={props.svgSource}>
        <img alt="icon" src={props.svgSource} />
      </SVG>
    </div>
  );
};

export default InlineSVG;
