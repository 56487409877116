export const campaignLength = [
  {
    label: "1 day",
    value: 1,
    valueInWeek: 1 / 7,
    visibleForSelection: false,
  },
  {
    label: "1 week",
    value: 7,
    valueInWeek: 1,
    visibleForSelection: true,
  },
  {
    label: "2 weeks",
    value: 14,
    valueInWeek: 2,
    visibleForSelection: true,
  },
  {
    label: "3 weeks",
    value: 21,
    valueInWeek: 3,
    visibleForSelection: true,
  },
  {
    label: "4 weeks",
    value: 28,
    valueInWeek: 4,
    visibleForSelection: true,
  },
  {
    label: "5 weeks",
    value: 35,
    valueInWeek: 5,
    visibleForSelection: true,
  },
];
