import { LOCATION_CHANGE } from "connected-react-router";

import {
  clearPlaceOrder,
  clearSelections,
  clearUserCardData,
  resetContactForm,
  loadLocations,
} from "actions";

export default ({ dispatch, getState }) => next => action => {
  if (action.type === LOCATION_CHANGE) {
    // Reset scroll to top
    window.scrollTo({ top: 0 });

    const state = getState();
    const location = action.payload.location.pathname;
    const prevLocation = state.router.location.pathname;

    if (prevLocation === "/account") {
      dispatch(clearUserCardData());
    }
    // Reset contact form when navigating away from it.
    if (prevLocation === "/contact") {
      dispatch(resetContactForm());
    }
    if (location === "/start" && prevLocation === "/thank-you") {
      dispatch(clearPlaceOrder());
      dispatch(clearSelections());
      dispatch(loadLocations());
    }
    if (location === "/login") {
      dispatch(clearSelections());
    }
  }

  return next(action);
};
