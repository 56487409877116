import React, { useState, useEffect } from "react";
import cn from "classnames";

/**
 * Show confirmation modal
 * @param {function} onCancel onCancel modal callback
 * @param {function} onConfirm onConfirm modal callback
 * @param {function} onError onError modal callback
 * @param {string} textAction Action text
 * @param {string} textMessage Message text
 * @param {string} textDetails Details text
 */
const useConfirmationModal = ({
  onCancel,
  onConfirm,
  textAction,
  textMessage,
  textDetails,
  buttonYes,
  buttonNo,
  warning,
  isLoading,
  error,
  textError,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleButtonClick = e => {
    e.stopPropagation();
    setIsVisible(true);
  };

  const handleCancelButtonClick = e => {
    e.stopPropagation();
    setIsVisible(false);

    if (typeof onCancel === "function") {
      onCancel();
    }
  };

  const handleConfirmButtonClick = e => {
    e.stopPropagation();

    if (typeof onConfirm === "function") {
      onConfirm();
    }
  };

  useEffect(() => {
    if (!isLoading && !error) {
      setIsVisible(false);
    }
  }, [isLoading, error]);

  const confirmation = isVisible && (
    <div className="confirmationModalWrapper" onClick={handleCancelButtonClick}>
      <div
        className="confirmationModalContent"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <div className="confirmationModalTextContainer">
          <p className="confirmationModalText-Action">{textAction}</p>
          <p className="confirmationModalText-Message">{textMessage}</p>
          <p className="confirmationModalText-Details">{textDetails}</p>
        </div>
        {isLoading && !error && (
          <div className="confirmationModalStatusContainer">
            <p>Confirming...</p>
          </div>
        )}
        {!isLoading && error && (
          <div className="confirmationModalStatusContainer">
            <p className="error">{textError}</p>
          </div>
        )}
        {!isLoading && !error && (
          <div className="confirmationModalButtonsContainer">
            <button
              className="confirmationModalButton buttonNo"
              onClick={handleCancelButtonClick}
              type="button"
            >
              {buttonNo}
            </button>
            <button
              className={cn("confirmationModalButton", { warning })}
              onClick={handleConfirmButtonClick}
              type="button"
            >
              {buttonYes}
            </button>
          </div>
        )}
        {!isLoading && <div className="confirmationModalClose" onClick={handleCancelButtonClick} />}
      </div>
    </div>
  );

  return {
    confirmation,
    handleButtonClick,
  };
};

export default useConfirmationModal;
