import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import alerts from "./alerts";
import apiHealth from "./apiHealth";
import auth from "./auth";
import campaigns from "./campaigns";
import campaignSectionsContent from "./campaignSectionsReducer";
import locations from "./locations";
import offers from "./offers";
import orders from "./orders";
import path from "./path";
import user from "./user";

const rootReducer = history =>
  combineReducers({
    alerts,
    apiHealth,
    auth,
    campaigns,
    campaignSectionsContent,
    locations,
    offers,
    orders,
    path,
    router: connectRouter(history),
    user,
  });

export default rootReducer;
