import { addDays, parse, subDays } from "date-fns";
import { getZonedDate } from "utils";

export const calculateAvailableDates = (
  offerFirstAvailableLaunchDate,
  offerLastAvailableLaunchDate,
  mediaMinimumLeadTime,
  minimumStartDate
) => {
  // the next available day is sent by the API via Offer.MinimumStartDate
  let dateNextAvailable = parse(minimumStartDate, "yyyy-MM-dd HH:mm:ss", new Date());

  const offerBeginDate = parse(offerFirstAvailableLaunchDate, "yyyy-MM-dd HH:mm:ss", new Date());

  dateNextAvailable = offerBeginDate > dateNextAvailable ? offerBeginDate : dateNextAvailable;

  // roll over weekends
  if (dateNextAvailable.getDay() === 6) {
    dateNextAvailable = addDays(dateNextAvailable, 2);
  } else if (dateNextAvailable.getDay() === 0) {
    dateNextAvailable = addDays(dateNextAvailable, 1);
  }

  let dateNextAvailableLater = addDays(dateNextAvailable, 1);

  // roll over weekends
  if (dateNextAvailableLater.getDay() === 6) {
    dateNextAvailableLater = addDays(dateNextAvailableLater, 2);
  } else if (dateNextAvailableLater.getDay() === 0) {
    dateNextAvailableLater = addDays(dateNextAvailableLater, 1);
  }

  const offerEndDate = parse(offerLastAvailableLaunchDate, "yyyy-MM-dd HH:mm:ss", new Date());

  let dateLastAvailable = offerEndDate;

  // roll back weekends
  if (dateLastAvailable.getDay() === 6) {
    dateLastAvailable = subDays(dateLastAvailable, 1);
  } else if (dateLastAvailable.getDay() === 0) {
    dateLastAvailable = subDays(dateLastAvailable, 2);
  }

  return {
    dateNextAvailable,
    dateNextAvailableLater,
    dateLastAvailable,
  };
};
