import { asyncActionType } from "utils/constants";

export const CAMPAIGN_PROMOTION_FIRST_MOUNT = "CAMPAIGN_PROMOTION_FIRST_MOUNT";
export const CLEAR_SELECTIONS = "CLEAR_SELECTIONS";
export const GET_OFFERS_BY_BRAND = asyncActionType("GET_OFFERS_BY_BRAND");
export const GET_OFFERS_BY_LOCATION = asyncActionType("GET_OFFERS_BY_LOCATION");
export const GET_STARTED = "GET_STARTED";
export const SELECT_PROMOTION = "SELECT_PROMOTION";
export const SELECT_OFFER = "SELECT_OFFER";
export const SELECT_OFFER_SIZE = "SELECT_OFFER_SIZE";
export const SELECT_PAYMENT_DISCLAIM = "SELECT_PAYMENT_DISCLAIM";
export const SELECT_SCHEDULE_CONFIRM = "SELECT_SCHEDULE_CONFIRM";
export const SELECT_SCHEDULE_DATE = "SELECT_SCHEDULE_DATE";
export const SELECT_SCHEDULE_DISCLAIM = "SELECT_SCHEDULE_DISCLAIM";
export const TOGGLE_AUTO_REPEAT = "TOGGLE_AUTO_REPEAT";
export const SELECT_SCHEDULE_REPEAT = "SELECT_SCHEDULE_REPEAT";
export const SELECT_SCHEDULE_START = "SELECT_SCHEDULE_START";
export const SELECT_SUBCATEGORY = "SELECT_SUBCATEGORY";
export const SELECT_SUMMARY = "SELECT_SUMMARY";
export const SELECT_CAMPAIGN_LENGTH = "SELECT_CAMPAIGN_LENGTH";
export const PREPOPULATE_SELECTIONS = "PREPOPULATE_SELECTIONS";
export const SAVE_SCHEDULE_DATE_PICKER = "SAVE_SCHEDULE_DATE_PICKER";
export const GET_HOLIDAYS = asyncActionType("GET_HOLIDAYS");
