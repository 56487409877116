import { combineReducers } from "redux";

import { CLEAR_PLACE_ORDER, GET_ORDERS_BY_LOCATION, PLACE_ORDER } from "constants/orders";

import { trackUserEvent } from "../utils";

const initialStateOrdersRequest = {
  error: undefined,
  isLoading: false,
  data: [],
};

const ordersRequest = (state = initialStateOrdersRequest, action) => {
  switch (action.type) {
    case GET_ORDERS_BY_LOCATION.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ORDERS_BY_LOCATION.SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
      };
    case GET_ORDERS_BY_LOCATION.FAILURE:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
    default:
      return state;
  }
};

const initialStateCampaignLaunch = {
  hasError: false,
  isLoading: false,
  isPlaced: false,
  errorMessage: "",
};

const campaignLaunch = (state = initialStateCampaignLaunch, action) => {
  switch (action.type) {
    case PLACE_ORDER.PENDING:
      return {
        ...state,
        hasError: false,
        isLoading: true,
        isPlaced: false,
      };
    case PLACE_ORDER.SUCCESS:
      return {
        ...state,
        hasError: false,
        isLoading: false,
        isPlaced: true,
        referenceNumber: action.payload.data.reference_number,
      };
    case PLACE_ORDER.FAILURE:
      trackUserEvent({
        event: "launch-error",
        "launch-error-message": action.error.response.data.message,
      });
      return {
        ...state,
        hasError: true,
        errorMessage:
          action.error.response.data.message.error || action.error.response.data.message,
        isLoading: false,
        isPlaced: false,
      };
    case CLEAR_PLACE_ORDER:
      return {
        ...state,
        ...initialStateCampaignLaunch,
      };
    default:
      return state;
  }
};

const ordersReducer = combineReducers({
  campaignLaunch,
  ordersRequest,
});

export default ordersReducer;
