import smoothscroll from "smoothscroll-polyfill";

smoothscroll.polyfill();

export const scrollToElement = ({ elem, scrollOffset = -80, smooth = true }) => {
  if (elem) {
    window.scrollTo({
      top: elem.getBoundingClientRect().top + window.pageYOffset + scrollOffset,
      behavior: smooth ? "smooth" : "auto",
    });
  } else {
    console.log("attempt scrollToElement on null element");
  }
};
