import { CLEAR_LOCATIONS, GET_LOCATION, GET_LOCATIONS } from "constants/locations";

/**
 * Get all locations associated with calling user
 */
export const loadLocations = () => ({
  types: [GET_LOCATIONS.PENDING, GET_LOCATIONS.SUCCESS, GET_LOCATIONS.FAILURE],
  payload: {
    request: {
      url: "locations?active=true",
    },
  },
});

/**
 * Get location details by location identifier
 * @param {number} locationId
 */
export const loadLocationById = locationId => ({
  types: [GET_LOCATION.PENDING, GET_LOCATION.SUCCESS, GET_LOCATION.FAILURE],
  payload: {
    request: {
      url: `locations/${locationId}`,
    },
  },
});

export const clearLocations = () => ({
  type: CLEAR_LOCATIONS,
});
