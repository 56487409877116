import {
  DEACTIVATE_ALERTS_BY_LOCATION,
  GET_ALERTS_BY_LOCATION,
  GET_NOT_VIEWED_ALERTS_TOTAL,
} from "constants/alerts";

/**
 * Get all alerts by location
 * @param {number} locationId
 */
export const loadAlertsByLocation = locationId => ({
  types: [
    GET_ALERTS_BY_LOCATION.PENDING,
    GET_ALERTS_BY_LOCATION.SUCCESS,
    GET_ALERTS_BY_LOCATION.FAILURE,
  ],
  payload: {
    request: {
      url: `locations/${locationId}/alerts`,
    },
  },
});

/**
 * Deactivate alerts by location
 * @param {array} alerts Array of Ids
 * @param {number} locationId
 */
export const deactivateAlertsByLocation = ({ alerts, locationId }) => ({
  types: [
    DEACTIVATE_ALERTS_BY_LOCATION.PENDING,
    DEACTIVATE_ALERTS_BY_LOCATION.SUCCESS,
    DEACTIVATE_ALERTS_BY_LOCATION.FAILURE,
  ],
  payload: {
    request: {
      method: "put",
      url: `locations/${locationId}/alerts/dismiss`,
      data: {
        Alerts: alerts,
      },
    },
  },
  meta: {
    alerts,
  },
});

/**
 * Get count of alerts not marked viewed
 * @param {number} locationId
 */
export const loadNotViewedAlertsTotal = locationId => ({
  types: [
    GET_NOT_VIEWED_ALERTS_TOTAL.PENDING,
    GET_NOT_VIEWED_ALERTS_TOTAL.SUCCESS,
    GET_NOT_VIEWED_ALERTS_TOTAL.FAILURE,
  ],
  payload: {
    request: {
      url: `locations/${locationId}/alerts/count`,
    },
  },
});
