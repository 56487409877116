/**
 * If two or more parallel requests make async call to the same resource with the same result
 * they will be grouped in one promise.
 */
export default class PromiseWithGrouping {
  constructor() {
    this.promise = {};
  }

  async run(group, getPromise) {
    try {
      if (!this.promise.hasOwnProperty(group)) {
        this.promise[group] = getPromise();
      }

      return await this.promise[group];
    } finally {
      delete this.promise[group];
    }
  }
}
